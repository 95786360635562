import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      '& img': {
        transform: 'scale(1.05)'
      },
      '& .makeStyles-prodName-283': {
        color: theme.palette.primary.main
      }
    }
  },
  media: {
    paddingBottom: '100%',
    margin: '0 auto',
    backgroundSize: 'contain'
  },
  cover: {
    position: 'relative',
    padding: theme.spacing(2),
    background: '#eef1f9',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  },
  imgWrapper: {
    width: '100%',
    '& > img': {
      verticalAlign: 'top',
      width: '100%',
      transition: 'transform .35s'
    }
  },
  prodContent: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  prodName: {
    lineHeight: '24px',
    fontSize: 16,
    color: '#6c7177',
    textAlign: 'center',
    marginBottom: 15,
    paddingBottom: 15,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 16,
      height: 3,
      background: theme.palette.primary.main,
      bottom: 0,
      left: '50%',
      marginLeft: -8
    }
  },
  prodPrice: {
    textAlign: 'center',
    color: '#262e31',
    lineHeight: '24px',
    fontSize: 16,
    fontWeight: 'bold'
  }
}));

function ProductItem (props) {
  const classes = useStyles();
  const { product, className, loggedIn } = props

  return (
    <div className={clsx(className, classes.root)}>
      <RouterLink to={`/products/${product.id}`}>
        <div className={classes.cover}>
          {
            product.coverImageUrl ? (
              <div className={classes.imgWrapper}>
                <img src={product.coverImageUrl + '-homesub'} alt=""/>
              </div>
            ) : (
              <Skeleton variant="rect" width="100%" className={classes.media} animation="wave"/>
            )
          }
        </div>
        <div className={classes.prodContent}>
          <Typography variant="subtitle2" className={classes.prodName}>{product.productName}</Typography>
          <Typography className={classes.prodPrice}>
            {
              loggedIn ? `$${product.price}` : 'Inquire Price'
            }
          </Typography>
        </div>
      </RouterLink>
    </div>
  );
}

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
})

export default connect(mapStateToProps)(ProductItem);