/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { productsConst } from '../constants';

const defaultState = {
  loading: false,
  uploadingPrimary: false,
  currencyCode: 'USD',
  products: [],
  home: {},
  error: ''
}

export function products (state = defaultState, action) {

  const { key, products } = action
  const { home } = state

  switch (action.type) {
    // 获取首页产品列表
    case productsConst.GET_HOME_REQUEST:

      home[key] = home[key] || {}
      home[key].loading = true
      
      return Object.assign({}, state, {
        home: {...home},
      })
    case productsConst.GET_HOME_SUCCESS:

      home[key] = home[key] || {}
      home[key].loading = false
      home[key].data = action.products

      return Object.assign({}, state, {
        home: {...home},
        currencyCode: action.currencyCode
      });
    case productsConst.GET_HOME_FAILURE:

      home[key] = home[key] || {}
      home[key].loading = false
      home[key].error = action.error

      return Object.assign({}, state, {
        home: {...home},
      });

    // 获取产品列表
    case productsConst.GET_REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case productsConst.GET_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        products: action.products,
        currencyCode: action.currencyCode,
        totalCount: action.totalCount
      });
    case productsConst.GET_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    default:
      return state
  }
}