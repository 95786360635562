export const productsConst = {

  // 获取首页产品列表
  GET_HOME_REQUEST: 'GET_HOME_PRODUCTS_REQUEST',
  GET_HOME_SUCCESS: 'GET_HOME_PRODUCTS_SUCCESS',
  GET_HOME_FAILURE: 'GET_HOME_PRODUCTS_FAILURE',

  // 获取产品列表
  GET_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_FAILURE: 'GET_PRODUCTS_FAILURE',

  // 获取单个产品
  GET_PRODUCT: 'GET_PRODUCT_REQUEST',
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILURE: 'GET_PRODUCT_FAILURE',

  // 获取产品模板
  GET_DEFAULT_PRODUCT: 'GET_DEFAULT_PRODUCT',

  // 添加购物车
  ADD_CART: 'ADD_CART',

  // 更新购物车
  MUTATE_CART: 'MUTATE_CART',
}