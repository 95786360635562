import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx'
import {
  Typography,
} from '@material-ui/core';

import { Container } from '..'

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 12
  },
  flex: {
    display: 'flex',
  },
  center: {
    alignItems: 'center',
  },
  row: {
    height: 20,
    lineHeight: '20px',
    '& > p': {
      marginRight: 10
    },
    marginBottom: 6
  },
}));


function Financial (props) {

  const classes = useStyles();
  const { order, currency } = props

  const discount = order.discount || 0
  const amount = order.subtotal * (1 - discount) + parseFloat(order.freight) + parseFloat(order.otherFee)

  return (
    <Container title="Financial Infomation">
      <div className={classes.content}>
        <div className={clsx(classes.row, classes.flex, classes.center)}>
          <Typography variant="body2">Products Amount: </Typography>
          <Typography variant="subtitle2" color="error">{currency.code}{order.subtotal}</Typography>
        </div>
        <div className={clsx(classes.row, classes.flex, classes.center)}>
          <Typography variant="body2">Freight: </Typography>
          <Typography variant="subtitle2" color="error">{currency.code}{order.freight}</Typography>
        </div>
        <div className={clsx(classes.row, classes.flex, classes.center)}>
          <Typography variant="body2">Discount: </Typography>
          <Typography variant="subtitle2" color="error">-{currency.code}{(order.subtotal * discount).toFixed(2)}</Typography>
        </div>
        <div className={clsx(classes.row, classes.flex, classes.center)}>
          <Typography variant="body2">Service Charge: </Typography>
          <Typography variant="subtitle2" color="error">{currency.code}{(order.otherFee).toFixed(2)}</Typography>
        </div>
        <div className={clsx(classes.row, classes.flex, classes.center)}>
          <Typography variant="body2">Total Amount: </Typography>
          <Typography variant="subtitle2" color="error">{currency.code}{amount.toFixed(2)}</Typography>
        </div>
      </div>
    </Container>
  );
}

const mapStateToProps = state => ({
  loading: state.orders.loading,
  order: state.orders.order,
  financial: state.orders.financial,
  currency: state.global.currency
})

const connectedFinancial = connect(mapStateToProps)(Financial)

export default connectedFinancial