export const brandsConst = {

  // 获取品牌列表
  GET_REQUEST: 'GET_BRANDS_REQUEST',
  GET_SUCCESS: 'GET_BRANDS_SUCCESS',
  GET_FAILURE: 'GET_BRANDS_FAILURE',

  // 取本地brands
  GET_BRANDS: 'GET_BRANDS',
  GET_CATEGORIES: 'GET_CATEGORIES',

  // 新增品牌
  ADD_REQUEST: 'ADD_BRAND_REQUEST',
  ADD_SUCCESS: 'ADD_BRAND_SUCCESS',
  ADD_FAILURE: 'ADD_BRAND_FAILURE',

}