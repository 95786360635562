import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom'
import { Badge, IconButton, Link } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { getCaches } from 'store/actions'

const Cart = props => {

  const { className, caches, getCaches } = props;
  const qtys = caches.reduce((cur, next) => cur + parseInt(next.qty), 0)
  const dispayedQtys = qtys > 999 ? '999+' : qtys

  useEffect(() => {
    getCaches()
  }, [getCaches])

  return (
    <Link
      className={className}
      component={RouterLink}
      to="/purchase-list"
      variant="h6"
    >
      <IconButton color="inherit">
        <Badge
          badgeContent={dispayedQtys}
          color="primary"
        >
          <ShoppingCartIcon color="secondary"/>
        </Badge>
      </IconButton>
    </Link>
  );
};

Cart.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = state => ({
  caches: state.caches.caches,
})

const mapDispatchToProps = dispatch => ({
  getCaches: () => dispatch(getCaches()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
