import React, { useState } from 'react';
import clsx from 'clsx';
import XLSX from 'xlsx';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Button, Paper, Dialog, DialogTitle, DialogContent, Typography } from '@material-ui/core';
import browserHistory from 'common/history';

import { SearchInput } from 'components';
import { Select } from 'components';
import { Upload, Download } from './components';
import { productsService, ordersService } from 'store/services';
import { alert } from 'store/actions';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  grow: {
    flexGrow: 1
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  link: {
    marginTop: 10,
    marginLeft: 8,
    display: 'block',
    cursor: 'pointer',
  },
  padding: {
    paddingLeft: 8,
    paddingTop: 5,
    paddingBottom: 5
  },
  note: {
    marginTop: theme.spacing(3),
    paddingLeft: 8
  },
  searchInput: {
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: 7,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
}));

const toJson = wb => {
  let results = []
  wb.SheetNames.forEach(sheetName => {
    const roa = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], { header : 1 });
    if(roa.length) {
      let [keys, ...rows] = roa
      rows = rows.filter(row => row.length > 0)
      results = rows.map(getRow)

      function getRow (data) {
        let row = {}
        keys.forEach((key, index) => {
          let trimedKey = key.trim()
          row[trimedKey] = data[index]
        })
        return row
      }
    }
  });
  return results
}

const QuotaToolbar = props => {

  const { className, brands, brand, search, handleChange, alert, ...rest } = props;
  const classes = useStyles();

  const [_search, setValue] = useState(search || '');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = ev => {
    setValue(ev.target.value)
  }

  const handleSearch = ev => {
    if (ev.key === 'Enter') {
      handleChange(ev)
    }
  }

  const handleClose = ev => {
    setOpen(false)
  }

  const createOrder = items => {
    setLoading(true);
    const skuIds = [];
    const skuMap = new Map();
    items.forEach(item => {
      skuIds.push(item.skuId);
      skuMap.set(item.skuId.toLowerCase(), item.qty);
    })
    productsService.fetchProductsBySkuIds(skuIds)
      .then(res => {
        if (res.status === 0) {
          let qty = 0;
          let subtotal = 0;
          const productItems = res.data;
          const orderItems = productItems.map(item => {
            item.qty = parseInt(skuMap.get(item.skuId.trim().toLowerCase()));
            qty += item.qty;
            subtotal += item.qty * item.salesPrice;
            return item;
          })
          return ordersService.createOrder({
            qty,
            subtotal,
            items: orderItems
          })
        }
      })
      .then(res => {
        if (res.status === 0 && res.data.orderID) {
          setLoading(false);
          alert({ type: 'success', message: 'Your order is created successfully.' });
          browserHistory.push(`/checkout/${res.data.orderID}`)
        } else {
          return Promise.reject()
        }
      })
      .catch(err => {
        setLoading(false);
        alert({ type: 'error', message: 'something went wrong :(, please try again..' });
      })
  }

  const handleUpload = e => {

    e.stopPropagation(); e.preventDefault();

    const files = e.target.files, f = files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {type: 'array'});

      const items = toJson(workbook);
      createOrder(items);
      
    };
    reader.readAsArrayBuffer(f);
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <Select
          options={brands}
          name="brand"
          defaultValue="Select Brand"
          value={brand}
          onChange={handleChange}
        />
        <Paper className={classes.searchInput}>
          <SearchInput
            name="search"
            value={_search}
            placeholder="Search product name, model id etc."
            onChange={handleInputChange}
            onKeyUp={handleSearch}
          />
        </Paper>
        <div className={classes.grow} />
        <Button variant="contained" color="primary" size="small" onClick={ev => setOpen(true)}>Import Order</Button>
        {/* <Upload label="Import Order" handleChange={handleUpload} id="import-upload"/> */}
      </div>
      <Dialog onClose={handleClose} fullWidth={true} maxWidth="sm" open={open}>
        <DialogTitle id="simple-dialog-title">Import order sheet</DialogTitle>
        <DialogContent>
          {
            loading ? (
              <Typography variant="subtitle2" className={classes.padding}>Processing data, please wait...</Typography>
            ) : (
              <Upload
                label="Import Order"
                handleChange={handleUpload}
                disabled={loading}
                id="import-upload"
              />
            )
          }
          <Download title="Download order template sheet >>" className={classes.link}/>
          <Typography variant="subtitle2" className={classes.note}>Note: skuId and qty is required in the order sheet.</Typography>
        </DialogContent>
        
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  alert: payload => dispatch(alert(payload)),
})

const connectedQuotaToolbar = connect(mapStateToProps, mapDispatchToProps)(QuotaToolbar);

export default connectedQuotaToolbar;
