import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut } from 'store/actions'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
 root: {
   padding: theme.spacing(2)
 }
}));

function SignOut (props) {

  const classes = useStyles()
  const { logOut } = props

  useEffect(() => {
    logOut()
  });

  return (
    <div className={classes.root}>
      <Typography variant="subtitle2">Loging out....</Typography>
    </div>
  )
}


const mapStateToProps = state => ({
  hasLogout: state.auth.hasLogout
})

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(logOut())
})

const connectedSignUp = connect(mapStateToProps, mapDispatchToProps)(withRouter(SignOut))

export default connectedSignUp
