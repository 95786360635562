import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core';


const schema = {
  userName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  }
};

const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
}));

const RegisterForm = props => {

  const { handleSubmit, email, token } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      email,
      token,
      cate: 102 // 标示客户分类：国外批发
    },
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignUp = event => {
    event.preventDefault();
    formState.isValid && handleSubmit(formState.values)
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <form
      className={classes.form}
      onSubmit={handleSignUp}
    >
      <Typography
        className={classes.title}
        variant="h2"
      >
        Sign up
      </Typography>
      <Typography
        color="textSecondary"
        gutterBottom
      >
        A verification email will be sent to your email address, which will be used as the <strong>login username</strong>. Please fill in carefully.
      </Typography>
      <TextField
        className={classes.textField}
        fullWidth
        readOnly
        label="Email address"
        name="email"
        type="text"
        value={email}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={hasError('userName')}
        fullWidth
        helperText={
          hasError('userName') ? formState.errors.userName[0] : null
        }
        label="User name"
        name="userName"
        onChange={handleChange}
        type="text"
        value={formState.values.userName || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={hasError('password')}
        fullWidth
        helperText={
          hasError('password') ? formState.errors.password[0] : null
        }
        label="Password"
        name="password"
        onChange={handleChange}
        type="password"
        value={formState.values.password || ''}
        variant="outlined"
      />
      <div className={classes.policy}>
        <Checkbox
          checked={formState.values.policy || false}
          className={classes.policyCheckbox}
          color="primary"
          name="policy"
          onChange={handleChange}
        />
        <Typography
          className={classes.policyText}
          color="textSecondary"
          variant="body1"
        >
          I have read the{' '}
          <Link
            color="primary"
            component={RouterLink}
            to="/terms"
            underline="always"
            variant="h6"
          >
            Terms and Conditions
          </Link>
        </Typography>
      </div>
      {hasError('policy') && (
        <FormHelperText error>
          {formState.errors.policy[0]}
        </FormHelperText>
      )}
      <Button
        className={classes.signUpButton}
        color="primary"
        disabled={!formState.isValid}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Sign up now
      </Button>
      <Typography
        color="textSecondary"
        variant="body1"
      >
        Have an account?{' '}
        <Link
          component={RouterLink}
          to="/sign-in"
          variant="h6"
        >
          Sign in
        </Link>
      </Typography>
    </form>
  );
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func
};

export default RegisterForm
