import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton'
import {
  Typography,
  TablePagination,
  Paper,
  TextField,
  Dialog,
  DialogTitle
} from '@material-ui/core';

import { styles, Loading } from './components';
import { fetchQuota, saveCart, updateCart } from 'store/actions';
import newIcon from 'assets/images/new.png';

const useStyles = makeStyles(theme => ({
  ...styles,
  root: {},
  content: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  product: {
    '& > h6': {
      minWidth: 60
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0`
  },
  avatar: {
    width: 60,
    height: 60,
    cursor: 'pointer',
  },
  new: {
    position: 'relative',
    '&::after': {
      content: '""',
      marginLeft: 2,
      display: 'inline-block',
      width: 30,
      height: 16,
      backgroundImage: `url(${newIcon});`,
      verticalAlign: -2
    }
  },
  input: {
    width: '50%'
  },
  header: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
    borderBottom: 'solid 1px #e3e3e3',
    alignItems: 'center'
  },
  placeholder: {
    padding: theme.spacing(6, 0, 3),
    '& > p': {
      fontSize: 24
    }
  },
  imgWrapper: {
    padding: theme.spacing(3),
  },
  img: {
    width: 400,
    height: 400,
    display: 'block',
    margin: 'auto',
  },
  disabled: {
    '& .MuiTypography-subtitle2, & .MuiTypography-body2': {
      color: 'rgba(0, 0, 0, .36)',
    },
    '& img': {
      filter: 'grayscale(100%)',
    }
  },
  hidden: {
    width: 0,
    height: 0,
    visibility: 'hidden'
  }
}));

const QuotaTable = props => {
  const { className, loading, items, currency, cartItems, totalCount, handleChange, updateCart, saveCart, ...options } = props;
  const classes = useStyles();
  const [imgPreview, setPreview] = useState({
    open: false,
    img: null
  })

  const handlePageChange = (event, page) => {
    handleChange({page: page + 1, pageSize: options.pageSize})
  };

  const handleRowsPerPageChange = event => {
    const pageSize = event.target.value
    handleChange({pageSize, page: 1})
  };

  const hasError = id => {
    let item = cartItems.find(item => item.id === id)
    return item && !item.qty ? true : false;
  }

  const handleAddToCart = (ev, item) => {
    let target = {...item}
    if (parseInt(target.qty) >= 0) {
      let index = cartItems.findIndex(t => t.id === item.id)

      if (index < 0) {
        saveCart([target])
      } else {
        updateCart(index, target)
      }
    }
  }

  const showImg = (ev, url) => {
    setPreview({
      open: true,
      loading: true,
      img: url + '-listview'
    })
  }

  const handleClose = ev => {
    setPreview({
      open: false,
      img: null
    })
  }

  const handleLoaded = ev => {
    setPreview({
      ...imgPreview,
      loading: false
    })
  }

  const getSelectedItem = id => cartItems.find(item => item.id === id) || {}

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Paper className={classes.content}>
        <div className={classes.header}>
          <div className={clsx(classes.product, classes.main, classes.marginRight)}>
            <Typography variant="h6" className={classes.marginRight}>Product</Typography>
            <Typography variant="h6" className={classes.marginRight}>Package</Typography>
          </div>
          <Typography variant="h6" className={clsx(classes.marginRight, classes.col)}>Sku ID</Typography>
          <Typography variant="h6" className={classes.colAuto}>Product Name</Typography>
          <Typography variant="h6" className={classes.col}>Price</Typography>
          <Typography variant="h6" className={clsx(classes.marginRight, classes.col)}>Qty / Carton</Typography>
          <Typography variant="h6" className={classes.col2}>Quantity</Typography>
          <Typography variant="h6" className={classes.col}>Total</Typography>
          <Typography variant="h6" className={classes.col}>Reminder</Typography>
        </div>
        <div className={classes.body}>
          {
            loading
              ? <Loading/>
              : items.length === 0
                ? (
                    <div className={classes.placeholder}>
                      <Typography variant="body2">No matching references found :(</Typography>
                    </div>
                  )
                : items.map((sku, index) => (
                    <div className={clsx(classes.row, { [classes.disabled]: sku.outOfProduction })} key={sku.id}>
                      <div className={clsx(classes.main, classes.marginRight)}>
                        <div className={clsx(classes.marginRight, classes.avatar)} onClick={ev => showImg(ev, sku.img)}>
                          <img src={sku.img + '?imageView2/1/w/60/h/60/ignore-error/1'} alt=""/>
                        </div>
                        <div className={clsx(classes.marginRight, classes.avatar)} onClick={ev => showImg(ev, sku.package)}>
                          <img src={sku.package + '?imageView2/1/w/60/h/60/ignore-error/1'} alt=""/>
                        </div>
                      </div>
                      <Typography variant="body2" className={clsx(classes.marginRight, classes.col)}>{sku.skuId}</Typography>
                      <Link to={`/products/${sku.productId}`} comments="div" className={classes.colAuto}>
                        <div>
                          <Typography variant="subtitle2" className={clsx(classes.title, { [classes.new]: sku.isNew })}>{sku.modelName}</Typography>
                          <Typography variant="body2">{sku.spec}</Typography>
                        </div>
                      </Link>
                      <Typography variant="subtitle2" className={clsx(classes.stress, classes.col)}>{currency.code}{sku.salesPrice}</Typography>
                      <Typography variant="subtitle2" className={clsx(classes.stress, classes.col)}>{sku.qtyPerCtn}</Typography>
                      <div variant="subtitle2" className={classes.col2}>
                        <TextField
                          label="Qty"
                          margin="dense"
                          name="qty"
                          type="number"
                          className={classes.input}
                          disabled={sku.outOfProduction}
                          onChange={ev => handleAddToCart(ev, Object.assign(sku, { qty: ev.target.value }))}
                          value={getSelectedItem(sku.id).qty || ''}
                          variant="outlined"
                          error={hasError(sku.id)}
                          helperText={
                            hasError(sku.id) ? 'qty is required' : null
                          }
                          InputLabelProps= {{
                            shrink: true
                          }}
                          inputProps= {{
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      </div>
                      <Typography variant="subtitle2" className={classes.col}>{currency.code}{((sku.qty || 0) * sku.salesPrice).toFixed(2)}</Typography>
                      <Typography variant="subtitle2" className={classes.col}>{sku.outOfProduction ? 'out of production' : sku.remark}</Typography>
                    </div>
                  ))
          }
        </div>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={options.page - 1}
          rowsPerPage={options.pageSize}
          rowsPerPageOptions={[10, 50, 100]}
        />
      </Paper>
      <Dialog open={imgPreview.open} fullWidth onClose={handleClose}>
        <DialogTitle>Image Preview</DialogTitle>
        <div className={classes.imgWrapper}>
          {
            imgPreview.loading
              ? <Skeleton width="400" height="400" variant="rect" animation="wave" className={classes.img}/>
              : <img src={imgPreview.img} alt="" className={classes.img}/>
          }
        </div>
        <img src={imgPreview.img} alt="" className={classes.hidden} onLoad={handleLoaded}/>
      </Dialog>
    </div>
  );
};

QuotaTable.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  loading: state.quota.loadingQuota,
  totalCount: state.quota.totalCount,
  items: state.quota.items,
  cartItems: state.caches.caches,
  currency: state.global.currency
})

const mapDispatchToProps = dispatch => ({
  fetchQuota: options => dispatch(fetchQuota(options)),
  saveCart: items => dispatch(saveCart(items)),
  updateCart: (index, item) => dispatch(updateCart(index, item)),
})

const connectedQuotaTable = connect(mapStateToProps, mapDispatchToProps)(QuotaTable)

export default connectedQuotaTable
