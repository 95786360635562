import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';

import { fetchQuota, getBrands } from 'store/actions'
import { QuotaToolbar, QuotaTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    width: theme.content.width,
    margin: '0 auto',
  },
  content: {
    marginTop: theme.spacing(2)
  },
  dataTable: {
    marginTop: theme.spacing(2)
  }
}));

const defaultState = {
  options: {
    pageIndex: 1,
    pageSize: 10,
    search: '',
    brand: ''
  },
  totalCount: 0,
  items: []
}

const Quota = props => {

  const classes = useStyles();
  const { fetchQuota, } = props;
  const { brands } = props;

  const [options, setOptions] = useState(defaultState.options)

  const handlePageChange = ({page, pageSize}) => {
    setOptions({
      ...options,
      pageIndex: page,
      pageSize
    })
  }

  const handleChange = ev => {
    setOptions({
      pageIndex: 1,
      pageSize: options.pageSize,
      [ev.target.name]: ev.target.value
    })
  }

  useEffect(() => {
    fetchQuota(options)
    getBrands()
  }, [fetchQuota, options])

  return (
    <div
      className={classes.root}
    >
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Home
        </Link>
        <Typography color="textPrimary">Price List</Typography>
      </Breadcrumbs>
      <div className={classes.content}>
        <QuotaToolbar
          brand={options.brand}
          search={options.search}
          brands={brands}
          handleChange={handleChange}
        />
        <QuotaTable
          className={classes.dataTable}
          page={options.pageIndex}
          pageSize={options.pageSize}
          handleChange={handlePageChange}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  brands: state.brands.brands
})

const mapDispatchToProps = dispatch => ({
  fetchQuota: (userId, options) => dispatch(fetchQuota(userId, options)),
  getBrands: dispatch(getBrands())
})

const connectedQuota = connect(mapStateToProps, mapDispatchToProps)(Quota)

export default connectedQuota