import React from 'react';
import XLSX from 'xlsx';
import { connect } from 'react-redux';

import { Button,} from '@material-ui/core';
import { alert } from 'store/actions'

function getFileName (order) {

  const date = new Date().toISOString().replace(/T.*/, '')
  const { _orderId } = order
  return `${date}-${_orderId}.xlsx`
}

function getData (order) {

  let { items } = order
  let cols = ['Model Name', 'Product Name', 'Color', 'Price', 'Qty', 'Amount']

  let data = items.map(item => [
    item.skuId,
    item.name,
    item.spec,
    item.salesPrice,
    item.qty,
    (item.qty * item.salesPrice).toFixed(2),
  ])

  return [cols, ...data]
}

function Download (props) {

  const { className, order, alert } = props

  const handleClick = ev => {
    
    alert({type: 'info', message: 'Processing data, please wait...'})

    try {
      const fileName = getFileName(order)
      const data = getData(order)
      const wsName = 'SheetJS'
    
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.aoa_to_sheet(data)
  
      XLSX.utils.book_append_sheet(wb, ws, wsName)

      XLSX.writeFile(wb, fileName)
    } catch (err) {
      console.error(err)
      alert({type: 'error', message:`Download failed :(`})
    }
  }

  return (
    <Button
      className={className}
      color="primary"
      variant="contained"
      size="small"
      onClick={handleClick}
    >
      Download Order
    </Button>
  )
}

const mapStateToProps = state => ({
  order: state.orders.order,
})

const mapDispatchToProps = dispatch => ({
  alert: payload => dispatch(alert(payload)),
})

const connectedDownload = connect(mapStateToProps, mapDispatchToProps)(Download)

export default connectedDownload