import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Carousel } from '..'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
    '& a': {
      display: 'block',
      width: '100%',
      height: '100%'
    }
  },
  img: {
    width: '100%',
    display: 'block'
  },
  dots: {
    bottom: 20,
    '& li button::before': {
      transition: 'transform 0.2s linear'
    },
    '& li.slick-active button::before': {
      color: theme.palette.primary.main,
      opacity: 1,
      transform: 'scale(1.5)'
    }
  }
}));

const banners = [
  {
    img: 'http://images2.diascubes.com/1666143048846-GAN13.jpg',
    link: '/products/1529',
  },
  {
    img: 'http://images2.diascubes.com/1666143114941-QY-Tornado-V3.jpg',
    link: '/products/1525',
  },
  {
    img: 'http://images2.diascubes.com/1666939363037-SUPERrs3m+1920x650海报.jpg',
    link: 'products/1532'
  },
  {
    img: 'http://images2.diascubes.com/1601800169762-moyu-meilong-series.jpg',
    link: '/products?brand=MOYU-MeiLong'
  }
]

const Banner = props => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    dotsClass: clsx(classes.dots, 'slick-dots')
  };

  return (
    <div className={classes.root}>
      <Carousel settings={settings}>
        {
          banners.map(banner => (
            <Link
              color="inherit"
              to={banner.link}
              variant="body2"
              key={banner.img}
            >
              <div key={banner}>
                <img src={banner.img} alt="First slide" className={classes.img} />
              </div>
            </Link>
          ))
        }
      </Carousel>
    </div>
  );
};

export default Banner;
