import { cacheConst } from '../constants';

const defaultState = {
  cachedProduct: null,
  key: 'USER_CART_CACHE_KEY',
  caches: []
}

export function caches (state = defaultState, action) {
  switch (action.type) {
    
    case cacheConst.GET_CART_LIST:
      let _caches = JSON.parse(localStorage.getItem(state.key)) || []
      return Object.assign({}, state, {
        caches: _caches
      });

    // 更新购物车
    case cacheConst.UPDATE_CART:

      let qty = parseInt(action.cache.qty)
      let caches = []
      if (action.index === 0) {
        caches = qty > 0 ? caches.concat(action.cache, state.caches.slice(1)) : state.caches.slice(1)
      } else if (action.index === state.caches.length - 1) {
        caches = qty > 0 ? caches.concat(state.caches.slice(0, -1), action.cache) : state.caches.slice(0, -1);
      } else if (action.index > 0) {
        caches = qty > 0 ? caches.concat(
          state.caches.slice(0, action.index),
          action.cache,
          state.caches.slice(action.index + 1)
        ) : caches.concat(
          state.caches.slice(0, action.index),
          state.caches.slice(action.index + 1)
        );
      }
      localStorage.setItem(state.key, JSON.stringify(caches))
      return Object.assign({}, state, {
        caches: caches
      });
        
    // 添加到购物车
    case cacheConst.ADD_CART:
      let index = state.caches.findIndex(cache => cache.id === action.cache.id)
      let old = state.caches[index]
      let newItems = []
      if (index < 0) {
        newItems = newItems.concat(action.cache, state.caches.slice(0))
      } else {
        let newItem = Object.assign({}, action.cache, { qty: parseInt(old.qty) + parseInt(action.cache.qty) })
        if (index === 0) {
          newItems = newItems.concat(newItem, state.caches.slice(1));
        } else if (index === state.caches.length - 1) {
          newItems = newItems.concat(newItem, state.caches.slice(0, -1));
        } else if (index > 0) {
          newItems = newItems.concat(
            newItem,
            state.caches.slice(0, index),
            state.caches.slice(index + 1)
          );
        }
      }
      localStorage.setItem(state.key, JSON.stringify(newItems))
      return Object.assign({}, state, {
        caches: newItems
      });

    // 从购物车中删除
    case cacheConst.REMOVE_CACHE_ITEM:

      let newCaches = []

      if (action.index === 0) {
        newCaches = newCaches.concat(state.caches.slice(1));
      } else if (action.index === state.caches.length - 1) {
        newCaches = newCaches.concat(state.caches.slice(0, -1));
      } else if (action.index > 0) {
        newCaches = newCaches.concat(
          state.caches.slice(0, action.index),
          state.caches.slice(action.index + 1)
        );
      }

      localStorage.setItem(state.key, JSON.stringify(newCaches))

      return Object.assign({}, state, {
        caches: newCaches
      });

    // 清空购物车
    case cacheConst.CLEAR_CART:
      localStorage.setItem(state.key, JSON.stringify([]))
      return Object.assign({}, state, {
        caches: []
      });
    default:
      return state
  }
}