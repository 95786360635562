import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import clsx from 'clsx'
import {
  Typography,
  IconButton,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { styles } from '..';
import { updateCart, removeItem } from 'store/actions'

const useStyles = makeStyles(theme => ({
  ...styles,
  body: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0`
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
  },
  input: {
    width: '60%',
  },
  stress: {
    color: '#333',
    fontWeight: 500
  }
}));

function PurchaseList (props) {

  const classes = useStyles()
  const { caches, disabled, currency } = props
  const { updateCart, removeItem } = props

  const handleChange = (index, sku, qty) => {
    updateCart(index, {
      ...sku,
      qty
    })
  }

  const handleRemove = index => {
    removeItem(index)
  }

  return (
    <div className={classes.body}>
      {
        caches.map((sku, index) => (
          <div className={classes.row} key={sku.id}>
            <div className={classes.main}>
              <img src={sku.img + '?imageView2/1/w/60/h/60/ignore-error/1'} alt=""/>
              <div className={classes.name}>
                <Typography variant="subtitle2">{sku.modelName}</Typography>
                <Typography variant="body2">{sku.spec}</Typography>
              </div>
            </div>
            <Typography variant="subtitle2" className={clsx(classes.stress, classes.price)}>{currency.code}{sku.salesPrice}</Typography>
            <div variant="subtitle2" className={classes.qty}>
              <TextField
                className={classes.input}
                label="Qty"
                margin="dense"
                name="qty"
                type="number"
                disabled={disabled}
                onChange={ev => handleChange(index, sku, ev.target.value)}
                required
                value={sku.qty}
                variant="outlined"
              />
            </div>
            <Typography variant="subtitle2" className={classes.total}>{currency.code}{(sku.qty * sku.salesPrice).toFixed(2)}</Typography>
            <div className={classes.action}>
              <IconButton
                edge="start"
                color="inherit"
                disabled={disabled}
                onClick={ev => handleRemove(index)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        ))
      }
    </div>
  )

}

const mapStateToProps = state => ({
  caches: state.caches.caches,
  currency: state.global.currency
})

const mapDispatchToProps = dispatch => ({
  updateCart: (index, item) => dispatch(updateCart(index, item)),
  removeItem: index => dispatch(removeItem(index)),
})

const connectedPurchaseList = connect(mapStateToProps, mapDispatchToProps)(PurchaseList)

export default connectedPurchaseList