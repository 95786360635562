import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { Toolbar, OrderTable, Loading } from './components'
import { fetchOrders } from 'store/actions'
import { EmptyList } from '../Common'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    width: theme.content.width,
    margin: '0 auto',
    flex: 1,
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

const defaultState = {
  options: {
    pageIndex: 1,
    pageSize: 10,
    search: '',
    maxItems: 5
  }
}

function Orders (props) {

  const classes = useStyles()
  const { orders, loading, currency } = props
  const { fetchOrders } = props

  const [options, setOptions] = useState(defaultState.options)

  const handleChange = ({name, value}) => {
    setOptions({
      ...options,
      [name]: value
    })
  }

  useEffect(() => {
    fetchOrders(options)
  }, [fetchOrders, options])

  return (
    <div className={classes.root}>
      <Toolbar handleChange={handleChange}/>
      {
        loading
          ? <Loading/>
          : orders.length > 0
            ? orders.map(order => <OrderTable key={order.id} order={order} currencyMark={currency.code} />)
            : <EmptyList
                title="You don't have any order"
                link="/products"
                linkText="find products"
              />
      }
    </div>
  )

}

const mapStateToProps = state => ({
  loading: state.orders.loading,
  orders: state.orders.orders,
  totalCount: state.orders.totalCount,
  currency: state.global.currency
})

const mapDispatchToProps = dispatch => ({
  fetchOrders: options => dispatch(fetchOrders(options)),
})

const connectedOrders = connect(mapStateToProps, mapDispatchToProps)(Orders)

export default connectedOrders