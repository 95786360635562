export default {
  main: {
    width: 180,
    display: 'flex',
    alignItems: 'center'
  },
  col: {
    flexGrow: 1,
    flexBasis: '10%'
  },
  col2: {
    flexGrow: 2,
    flexBasis: '20%'
  },
  colAuto: {
    flexGrow: 10,
    flexBasis: '25%',
    flexShrink: 0
  }
}