import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx'
import {

} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  cover: {
    width: 380,
    height: 380,
  },
  thumbnails: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  thumbnail: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0
    }
  },
  active: {
    boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
  }
}));

const Images = (props) => {

  const classes = useStyles();
  const { cover, images } = props
  const [primary, setPrimary] = useState({})
  
  useEffect(() => {
    setPrimary(cover)
  }, [cover])

  return (
    <div className={classes.root}>
      <div className={classes.cover}>
        <img src={primary.url + '-detailsub'} alt=""/>
      </div>
      <div className={classes.thumbnails}>
        {
          images.map(img => (
            <div className={clsx({
                [classes.thumbnail]: true,
                [classes.active]: img.url === primary.url
              })}
              key={img.url}
              onClick={ev => setPrimary(img)}
            >
              <img src={img.url + '?imageView2/1/w/60/h/60/ignore-error/1'} alt=""/>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default Images
