import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import qs from 'query-string'
import {
  Paper,
  Breadcrumbs,
  Typography,
  TablePagination,
} from '@material-ui/core';

import { ProductsFilters, ProductsList } from './components';
import { fetchProducts, getBrands } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    width: theme.content.width,
    margin: '0 auto'
  },
  content: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  upperCase: {
    textTransform: 'upperCase'
  }
}));

const defaultState = {
  options: {
    pageIndex: 1,
    pageSize: 20,
  }
}

const Products = (props) => {

  const classes = useStyles();
  const { fetchProducts, getBrands, location, totalCount = 10 } = props
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState(Object.assign({}, defaultState.options))
  let search = qs.parse(location.search)

  const handlePageChange = (event, page) => {
    setPage(page)
    setOptions({
      ...options,
      pageIndex: page + 1,
    })
  }

  const handleRowsPerPageChange = event => {
    const pageSize = event.target.value
    setRowsPerPage(pageSize);
    setOptions({
      ...options,
      pageSize
    })
  };

  useEffect(() => {
    fetchProducts(Object.assign({}, options, search))
  }, [search, options, fetchProducts])
  
  useEffect(() => {
    getBrands()
  }, [getBrands])

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          Home
        </Link>
        <Typography color="textPrimary" className={classes.upperCase}>{ search.search || search.brand || 'Product List' }</Typography>
      </Breadcrumbs>
      <Paper className={classes.content}>
        <ProductsFilters current={{brand: search.brand, category: search.category}}/>
        <ProductsList/>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[20, 50, 100]}
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = state => ({
  // loading: state.products.loading,
  brands: state.brands.brands,
  // products: state.products.products,
  totalCount: state.products.totalCount
})

const mapDispatchToProps = dispatch => ({
  fetchProducts: options => dispatch(fetchProducts(options)),
  getBrands: () => dispatch(getBrands()),
})

const connectedProducts = connect(mapStateToProps, mapDispatchToProps)(Products)

export default connectedProducts
