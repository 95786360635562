export const currencyList = [
  {
    label: '人民币',
    value: 'RMB',
    code: '￥',
  },
  {
    label: '美元',
    value: 'USD',
    code: '$',
  },
  {
    label: '欧元',
    value: 'EUR',
    code: '€',
  },
  {
    label: '英镑',
    value: 'GBP',
    code: '£'
  },
  {
    label: '日元',
    value: 'JPY',
    code: '¥'
  }
]