import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles';
import { Slide } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';

import { alert } from '../../store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.snackbar,
    top: 0,
    [theme.breakpoints.up('sm')]: {
      top: 0
    }
  },
}));

function CustomAlerts (props) {

  const { type, message, open=false, close, direction = 'down' } = props
  const classes = useStyles()

  const handleClose = () => {
    close()
  }

  useEffect(() => {
    let timer
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      open && close()
    }, 3500);
    return () => clearTimeout(timer)
  })

  return (
    <div className={clsx(classes.root, { [classes.width]: direction === 'left' })}>
      <Slide
        direction={direction}
        in={open}
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 200,
          appear: 2000,
          exit: 200
        }}
      >
        <MuiAlert onClose={handleClose} severity={type} elevation={6} variant="filled">
          {message}
        </MuiAlert>
      </Slide>
    </div>
  );
}

const mapStateToProps = state => ({
  open: state.alert.open,
  type: state.alert.alertType,
  message: state.alert.message
})

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(alert({type: 'close'}))
})

CustomAlerts.propTypes = {
  type: PropTypes.string,
  message: PropTypes.any,
};

const connectedCustomAlerts = connect(mapStateToProps, mapDispatchToProps)(CustomAlerts)

export default connectedCustomAlerts