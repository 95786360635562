import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

import { Countries } from 'components'
import { updateUser, patchUser } from 'store/actions'

const useStyles = makeStyles(() => ({
  root: {}
}));

const schema = {
  firstname: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  lastname: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  mobile: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  country: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  city: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const AccountDetails = props => {

  const { className, user, loadingUser, patchUser, updateUser } = props;
  const [formState, setFormState] = useState({
    isValid: false,
    touched: {},
    errors: {},
    loaded: false
  });

  const classes = useStyles();

  const CountrySelectorInput = props => (
    <TextField
      fullWidth
      disabled={loadingUser}
      required
      helperText={
        hasError('country') ? formState.errors.country[0] : null
      }
      label="Country"
      margin="dense"
      name="country"
      value={user.country || ''}
      variant="outlined"
      InputLabelProps={{shrink: true}}
      inputProps={{
        autoComplete: 'new-password', // disable autocomplete and autofill
      }}
      {...props}
    />
  )

  const handleChange = ev => {
    ev.persist();
    updateUser({
      [ev.target.name]: ev.target.value
    })
  };

  const handleCountryChange = (ev, country) => {
    if (!country) return
    updateUser({
      country: country.label
    })
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSave = ev => {
    if (formState.isValid) {
      patchUser(user)
    }
  }

  useEffect(() => {
    const errors = validate(user, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [user]);

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="Required fields are marked with *"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled={loadingUser}
                helperText={
                  hasError('firstname') ? formState.errors.firstname[0] : null
                }
                label="First name"
                margin="dense"
                name="firstname"
                onChange={handleChange}
                required
                value={user.firstname || ''}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                inputProps={{
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled={loadingUser}
                helperText={
                  hasError('lastname') ? formState.errors.lastname[0] : null
                }
                label="Last name"
                margin="dense"
                name="lastname"
                onChange={handleChange}
                required
                value={user.lastname || ''}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                inputProps={{
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled={loadingUser}
                required
                helperText={
                  hasError('mobile') ? formState.errors.mobile[0] : null
                }
                label="Phone Number"
                margin="dense"
                name="mobile"
                onChange={handleChange}
                type="text"
                value={user.mobile || ''}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                inputProps={{
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled={loadingUser}
                label="Facebook"
                margin="dense"
                name="facebook"
                onChange={handleChange}
                value={user.facebook || ''}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                inputProps={{
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Countries
                name="country"
                value={user.country}
                handleChange={handleCountryChange}
                TextField={CountrySelectorInput}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled={loadingUser}
                required
                helperText={
                  hasError('city') ? formState.errors.city[0] : null
                }
                label="City"
                margin="dense"
                name="city"
                onChange={handleChange}
                value={user.city || ''}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                inputProps={{
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            disabled={loadingUser}
            onClick={handleSave}
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.user.user,
  loadingUser: state.user.loadingUser
})

const mapDispatchToProps = dispatch => ({
  patchUser: user => dispatch(patchUser(user)),
  updateUser: user => dispatch(updateUser(user)),
})

const connectedAccountDetails = connect(mapStateToProps, mapDispatchToProps)(AccountDetails)

export default connectedAccountDetails
