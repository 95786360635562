import { globalConst, userConst } from '../constants';
import { currencyList } from './currency';

const defaultState = {
  loading: false,
  sidebarOpen: false,
  hasSentMessage: false,
  ad: {},
  currencyList: currencyList,
  currency: {
    label: '美元',
    value: 'USD',
    code: '$',
  },
}

export function global (state = defaultState, action) {
  switch (action.type) {
    case globalConst.SHOW_LOADING:
      return Object.assign({}, state, {
        loading: true,
      })
    case globalConst.CLOSE_LOADING:
      return Object.assign({}, state, {
        loading: false,
      })
    case globalConst.OPEN_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpen: true,
      })
    case globalConst.CLOSE_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpen: false,
      })
    case globalConst.SEND_MESSAGE:
      return Object.assign({}, state, {
        hasSentMessage: true,
      })
    
    case globalConst.GET_AD:
      return Object.assign({}, state, {
        ad: action.ad,
      })
    case userConst.AUTH_SUCCESS:
    case userConst.LOGIN_SUCCESS:
      const { currencyCode = 'USD' } = action.user || {}
      const currency = currencyList.find(cur => cur.value === currencyCode)
      return Object.assign({}, state, {
        currency
      });
    default:
      return state
  }
}