import * as React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { TableRow, TableCell, TableFooter } from '@material-ui/core';

const statusClass = {
  WAIT_BUYER_CONFIRM: 201,
  WAIT_SALLER_CONFIRM: 202,
  
  WAIT_PAY: 203,
  WAIT_DELIVERY: 204,
  WAIT_RECEIVE: 205,
  COMPLETE: 206,
  CLOSED: -999,
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      border: 0
    },
  }),
)
export default function Footer (props) {

  const classes = useStyles()
  const { order } = props

  const { trackStatus } = order
  const orderLink = trackStatus === statusClass.WAIT_BUYER_CONFIRM
    ? `/checkout/${order._orderId}` : `/orders/${order._orderId}`

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={7} className={classes.root}>
          <Link to={orderLink}>
            View Detail >>
          </Link>
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}
