import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import {
  Breadcrumbs,
  Typography,
  Grid,
} from '@material-ui/core';

import { AccountProfile, AccountDetails } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    width: theme.content.width,
    margin: '0 auto',
    flex: 1,
  },
  content: {
    marginTop: theme.spacing(3),
  }
}));

const Account = () => {
  const classes = useStyles();
  const user = {
    name: 'Shen Zhi',
    city: 'Los Angeles',
    country: 'USA',
    timezone: 'GTM-7',
    avatar: '/images/avatars/avatar_11.png'
  };

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          Home
        </Link>
        <Typography color="textPrimary">Profile</Typography>
      </Breadcrumbs>
      <Grid
        container
        spacing={4}
        className={classes.content}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <AccountProfile user={user} />
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <AccountDetails />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  emailSent: state.auth.emailSent
})

const mapDispatchToProps = dispatch => ({
  // sendEmail: body => dispatch(sendEmail(body)),
  // refresh: () => dispatch(refresh()),
})

const connectedAccount = connect(mapStateToProps, mapDispatchToProps)(Account)

export default connectedAccount
