import { confirmConst } from '../constants';


export function confirm (message) {

  let promise = null

  return dispatch => {

    return new Promise((resolve, reject) => {

      promise = { resolve, reject }
      dispatch(open(message, promise))
    })
  }
}

export function reject () {

  return dispatch => {
    dispatch(close())
  }
}

export function close () {
  return {
    type: confirmConst.CLOSE_CONFIRM
  }
}

function open (message, promise) {
  return {
    type: confirmConst.OPEN_CONFIRM,
    message,
    promise
  }
}