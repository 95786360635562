import * as qs from 'query-string'
import * as md5 from 'md5'

const headers = new Headers()
headers.append('Content-Type', 'application/json')
const appKey = 'DC1020'
const appSecret = 'c4edd23b928043a9bf6b52e570045e06'

export function getAuthToken (path, method, query) {
  path = path.replace(/\?.*/, '')
  const params = {
    appKey,
    path,
    method,
    appSecret
  }
  if (query) {
    for (let key in query) {
      params[key] = query[key]
    }
  }
  const sortedParams = Object.keys(params).sort()
    .map(key => ({ key: key, value: params[key] }))
    .filter(item => typeof item.value !== 'undefined')

  const sign = sortedParams.reduce(
    (prev, next) => prev + next.value,
    ''
  )
  return md5(sign)
}

export default class request {
  static get (options) {
    let { url, params, headers: _headers } = options
    let queryString = params ? qs.stringify(params) : ''
    let _url = `/api${url}?${queryString}`
    let sign = getAuthToken(url, 'GET', params)

    if (_headers) {
      _headers.set('authToken', sign)
    }
    headers.set('authToken', sign)
    return fetch(_url, {
      credentials: 'include',
      headers: _headers || headers
    })
      .then(res => res.json())
  }

  static post (options) {
    let { url, headers: _headers, params, body, multiple } = options
    let queryString = params ? qs.stringify(params) : ''
    let _url = `/api${url}?${queryString}`
    let sign = getAuthToken(url, 'POST', params)
    if (_headers) {
      _headers.set('authToken', sign)
    }
    headers.set('authToken', sign)
    return fetch(_url, {
      method: 'POST',
      credentials: 'include',
      headers: _headers || headers,
      body: multiple ? body : JSON.stringify(body)
    })
      .then(res => res.json())
  }

  static put (options) {
    let { url, headers: _headers, body, params } = options
    let queryString = params ? qs.stringify(params) : ''
    let _url = `/api${url}?${queryString}`
    let sign = getAuthToken(url, 'PUT', params)
    if (_headers) {
      _headers.set('authToken', sign)
    }
    headers.set('authToken', sign)
    return fetch(_url, {
      method: 'PUT',
      credentials: 'include',
      headers: _headers || headers,
      body: JSON.stringify(body)
    })
      .then(res => res.json())
  }

  static delete (options) {
    let { url, headers: _headers } = options
    url = `/api${url}`
    let sign = getAuthToken(url, 'DELETE')
    if (_headers) {
      _headers.set('authToken', sign)
    }
    headers.set('authToken', sign)
    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: _headers || headers,
    })
      .then(res => res.json())
  }
}