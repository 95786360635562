import * as React from 'react'
import { TableBody, TableRow, TableCell, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './style';

export default function ProductTable (props) {

  const classes = useStyles()
  const { order, currencyMark } = props

  const primaryPrice = parseFloat(order.subtotal) + parseFloat(order.freight)
  const totalAmount = parseFloat(order.subtotal) * (1 - order.discount) + parseFloat(order.freight)

  return (
    <TableBody className={classes.root}>
      {
        order.items.map((item, index) => (
          <TableRow key={item.id}>
            <TableCell className={classes.mainCell} colSpan={4}>
              <div className={classes.flex}>
                <div className={classes.flexGrow1}>
                  <img
                    src={item.img + '?imageView2/1/w/60/h/60/ignore-error/1'}
                    alt=""
                  />
                </div>
                <div className={clsx(classes.flex, classes.flexColumn, classes.justifyBetween, classes.flexGrow3)}>
                  <div>{item.name}</div>
                  <div>{item.spec}</div>
                </div>
                <div className={classes.flexGrow1}>× {item.qty}</div>
                <div className={classes.flexGrow1}>{currencyMark}{item.salesPrice.toFixed(2)}</div>
                <div className={classes.flexGrow1}>{currencyMark}{(item.qty * item.salesPrice).toFixed(2)}</div>
                <div className={classes.flexGrow1}>{item.remark || ''}</div>
              </div>
            </TableCell>
            {
              index === 0 ?
                (
                  <>
                    <TableCell
                      rowSpan={order.items.length}
                    >{currencyMark}{order.subtotal}</TableCell>
                    <TableCell
                      rowSpan={order.items.length}
                    >{currencyMark}{order.freight}</TableCell>
                    <TableCell
                      rowSpan={order.items.length}
                    >
                      { order.discount > 0 ? <Typography className={classes.lineThrough}>{currencyMark}{primaryPrice}</Typography> : null }
                      <Typography variant="subtitle2">{currencyMark}{totalAmount.toFixed(2)}</Typography>
                    </TableCell>
                  </>
                )
                : null
            }
          </TableRow>
        ))
      }
    </TableBody>
  )
}
