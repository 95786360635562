import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import { Container } from '..';
import { handleUpload } from 'store/actions';
import { ordersService } from 'store/services';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  pic: {
    marginTop: 20
  }
}));

export default function Upload (props) {

  const classes = useStyles();
  const { id, order, updateOrder } = props

  const handleChange = ev => {
    const file = ev.target.files[0]
    handleUpload(file)
      .then(res => {
        if (res.status === 0) {
          const url = res.data.url
          ordersService.patchOrder({
            ...order,
            receipts: res.data.url
          })
            .then(res => {
              if (res.status === 0) {
                updateOrder({
                  ...order,
                  receipts: url
                })
              }
            })
        }
      })
  }

  return (
    <Container title="Payment Details">
      <div className={classes.root}>
        <div className={classes.upload}>
          <input
            accept="image/*"
            className={classes.input}
            id={id}
            multiple
            type="file"
            onChange={handleChange}
          />
          <label htmlFor={id}>
            <Button variant="contained" color="primary" component="span" size="small">
              upload bank slip
            </Button>
          </label>
        </div>
        <div className={classes.pic}>
          <img src={order.receipts+'?imageView2/1/w/160/h/160/ignore-error/1'} alt=""/>
        </div>
      </div>
    </Container>
  );
}

Upload.propTypes = {
  id: PropTypes.string.isRequired
}
