import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import validate from 'validate.js';
import {
  Typography,
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import { alert } from 'store/actions'
import { ordersService, userService } from 'store/services'
import schema from './schema'
import { Countries } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  content: {
    marginTop: theme.spacing(1),
  },
  block: {
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  }
}));

const getKeys = obj => {
  let _obj = {}
  Object.keys(obj).forEach(each => {
    if (!_obj[each]) {
      _obj[each] = ''
    }
  })
  return _obj
}

function AddressForm (props) {

  const classes = useStyles()
  const { alert, handleCreate } = props

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      ...getKeys(schema)
    },
    touched: {},
    errors: {}
  });

  const [loading, setLoading] = useState(false)

  const defaultTextFieldProps = {
    fullWidth: true,
    margin: 'dense',
    variant: 'outlined',
    InputLabelProps: {
      shrink: true
    },
    inputProps: {
      autoComplete: 'new-password', // disable autocomplete and autofill
    }
  }

  const [cities, setCities] = useState([])

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const stateOption = {
    options: cities.length ? {
      select: true,
      SelectProps: {
        native: true,
      }
    } : {},
    children: cities.length ? cities.map(city => <option key={city.id} value={city.name}>{city.name}</option>) : null
  }

  const CountrySelector = params => (
    <TextField
      {...defaultTextFieldProps}
      error={hasError('country')}
      helperText={
        hasError('country') ? formState.errors.country[0] : null
      }
      label="Country"
      name="country"
      required
      onChange={handleChange}
      value={formState.values.country}
      {...params}
    />
  )

  const handleCountryChange = (ev, country) => {

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        country: country ? country.label : ''
      },
      touched: {
        ...formState.touched,
        country: true
      }
    }));

    country && ordersService.fetchCities({name: country.label})
      .then(res => {
        if (res.status === 0) {
          setCities(res.data)
        }
      })
  }

  const handleSave = ev => {
    if (formState.isValid) {
      setLoading(true)
      userService.createAddress(formState.values)
        .then(res => {
          if (res.status === 0) {
            alert({type: 'success', message: 'Address is created successfully.'})
            handleCreate(res.data)
          }
        })
    }
  }

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  return (
    <div className={classes.root}>
      <Typography variant="subtitle2">Enter your address info (required filds are marked with *)</Typography>
      <Grid container item xs={12} spacing={1} className={classes.content}>
        <Grid item xs={6}>
          <TextField
            {...defaultTextFieldProps}
            className={classes.textField}
            error={hasError('firstname')}
            helperText={
              hasError('firstname') ? formState.errors.firstname[0] : null
            }
            required
            label="Firstname"
            name="firstname"
            onChange={handleChange}
            value={formState.values.firstname}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...defaultTextFieldProps}
            className={classes.textField}
            error={hasError('lastname')}
            required
            helperText={
              hasError('lastname') ? formState.errors.lastname[0] : null
            }
            label="Lastname"
            name="lastname"
            onChange={handleChange}
            value={formState.values.lastname}
          />
        </Grid>
        <Grid item xs={12}>
          <Countries
            value={formState.values.country}
            handleChange={handleCountryChange}
            TextField={CountrySelector}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...stateOption.options}
            {...defaultTextFieldProps}
            label="State/Province"
            name="state"
            value={formState.values.state}
            onChange={handleChange}
            placeholder="optional"
          >
            { stateOption.children }
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...defaultTextFieldProps}
            helperText={
              hasError('city') ? formState.errors.city[0] : null
            }
            label="City"
            name="city"
            required
            value={formState.values.city}
            onChange={handleChange}
          >
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...defaultTextFieldProps}
            helperText={
              hasError('address') ? formState.errors.address[0] : null
            }
            label="Address"
            name="address"
            required
            value={formState.values.address}
            onChange={handleChange}
            placeholder="The specific address to which the package can be delivered"
          >
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...defaultTextFieldProps}
            helperText={
              hasError('phone') ? formState.errors.phone[0] : null
            }
            label="Mobile"
            name="phone"
            required
            value={formState.values.phone}
            onChange={handleChange}
            placeholder="+86 166xxxxxx"
          >
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...defaultTextFieldProps}
            helperText={
              hasError('zip') ? formState.errors.zip[0] : null
            }
            label="Zip code"
            name="zip"
            required
            value={formState.values.zip}
            onChange={handleChange}
          >
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.values.isDefault || false}
                onChange={handleChange}
                name="isDefault"
                color="primary"
                value={1}
              />
            }
            label="Save as default address"
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        disabled={!formState.isValid || loading}
        className={classes.button}
        onClick={handleSave}
      >Save Address</Button>
    </div>
  )

}

const mapStateToProps = state => ({
  caches: state.caches.caches,
})

const mapDispatchToProps = dispatch => ({
  alert: payload => dispatch(alert(payload)),
})

const connectedAddressForm = connect(mapStateToProps, mapDispatchToProps)(AddressForm)

export default connectedAddressForm