import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    '& > h6': {
      fontWeight: 400
    }
  },
  key: {
    width: 160,
    color: '#999'
  },
}));

function Footer (props) {

  const classes = useStyles()
  const { order } = props

  // const qtys = items.reduce((cur, next) => cur + parseInt(next.qty), 0)
  // const subtotal = items.reduce((cur, next) => cur + parseInt(next.qty) * next.salesPrice, 0)

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Typography variant="h6" className={classes.key}>Quatities:</Typography>
        <Typography variant="h6">{order.qty}</Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="h6" className={classes.key}>Shipping Fee:</Typography>
        <Typography variant="h6">To be Confirmed</Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="h6" className={classes.key}>Total:</Typography>
        <Typography variant="h6">${order.subtotal.toFixed(2)}</Typography>
      </div>
    </div>
  )

}

export default Footer