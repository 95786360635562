import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';


const ProtectedRoute = props => {

  const { loggedIn, loggingIn, authorized, component: Component, ...rest } = props;
  const redirectToLogin = !(loggedIn || loggingIn)
  const redirectToForbidden = loggedIn && !authorized

  return (
    <Route
      {...rest}
      render={matchProps => (
        redirectToLogin
          ? <Redirect to={{ pathname: '/sign-in', state: { from: matchProps.location } }}/>
          : redirectToForbidden
            ? <Redirect to={{ pathname: '/forbidden', state: { from: matchProps.location } }}/>
            : <Component {...matchProps} loggingIn={loggingIn} />
      )}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  path: PropTypes.string
};

export default ProtectedRoute;
