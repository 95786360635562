import { productsConst } from '../constants';
import { productsService } from '../services';

// 获取单个商品
export function fetchProduct (id) {

  return (dispatch, getState) => {

    const { products } = getState()
    if (products.loadingSingle) return

    dispatch(getRequest())

    productsService.fetchProduct (id)
      .then(res => {
        if (res.status === 0) {
          dispatch(getSuccess(res.data))
        } else {
          return Promise.reject(res.message)
        }
      })
      .catch(err => {
        dispatch(getFailure(err.message))
      })
  }

  function getRequest () {
    return {
      type: productsConst.GET_PRODUCT
    }
  }

  function getSuccess (product) {
    return {
      type: productsConst.GET_PRODUCT_SUCCESS,
      product,
    }
  }

  function getFailure (error) {
    return {
      type: productsConst.GET_PRODUCT_FAILURE,
      error
    }
  }
}