import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

export default function Upload (props) {

  const classes = useStyles();
  const { label, id, handleChange, disabled } = props

  return (
    <div className={classes.root}>
      <input
        accept="xlsx"
        className={classes.input}
        id={id}
        multiple
        type="file"
        onChange={handleChange}
      />
      <label htmlFor={id}>
        <Button variant="contained" color="primary" component="span" size="small" disabled={disabled}>
          {label}
        </Button>
      </label>
    </div>
  );
}

Upload.propTypes = {
  id: PropTypes.string.isRequired
}
