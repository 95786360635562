import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
} from '@material-ui/core';

import styles from '../styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
    borderBottom: 'solid 1px #e3e3e3'
  },
  ...styles
}));

function Header (props) {

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.main}>Product</Typography>
      <Typography variant="h6" className={classes.price}>Price</Typography>
      <Typography variant="h6" className={classes.qty}>Quantity</Typography>
      <Typography variant="h6" className={classes.total}>Total</Typography>
      <Typography variant="h6" className={classes.action}></Typography>
    </div>
  )

}

export default Header