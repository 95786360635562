export const ordersConst = {

  GET_ORDER_LIST: 'GET_ORDER_LIST',
  GET_ORDER: 'GET_ORDER',
  PATCH_ORDER: 'PATCH_ORDER',
  GET_ORDER_LIST_REQUEST: 'GET_ORDER_LIST_REQUEST',
  SEARCH_QUOTA: 'SEARCH_QUOTA',
  ADD_NEW_ITEMS: 'ADD_NEW_ITEMS',
  GET_ITEMS_TO_BE_ADDED: 'GET_ITEMS_TO_BE_ADDED',
  ADD_ORDER_ITEMS: 'ADD_ORDER_ITEMS',
  GET_FINANCIAL: 'GET_FINANCIAL',
  UPDATE_FINANCIAL: 'UPDATE_FINANCIAL',
  PATCH_REQUEST: 'PATCH_REQUEST',
  PATCH_SUCCESS: 'PATCH_SUCCESS',
  PATCH_FAILURE: 'PATCH_FAILURE',
}