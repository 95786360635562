import React from 'react';
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Paper
} from '@material-ui/core';

import { Select } from 'components'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  paperContent: {
    paddingTop: 12
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  key: {
    width: 120,
    flex: 'none',
  },
  charge: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  }
}));

const methods = [
  {
    label: 'Paypal',
    value: 'Paypal',
    charge: '4%'
  },
  {
    label: 'Western Union',
    value: 'Western Union',
    charge: 0
  },
  {
    label: 'Bank Transfer(USD)',
    value: 'Bank Transfer(USD)',
    charge: '$20'
  },
  {
    label: 'Bank Transfer(RMB)',
    value: 'Bank Transfer(RMB)',
    charge: 0
  },
]

function Payment (props) {

  const classes = useStyles()
  const { method, onChange, className } = props
  const { charge = 0 } = methods.find(m => m.value === method) || {}

  return (
    <Paper className={clsx(classes.paper, className)}>
      <Typography variant="h4">Payment</Typography>
      <Typography variant="subtitle2">Payment Method:</Typography>
      <div className={classes.paperContent}>
        <Select
          name="paymentMethod"
          value={method}
          onChange={onChange}
          options={methods}
        />
      </div>
      <div className={classes.charge}>
        <Typography variant="subtitle2">Service charge: {charge}</Typography>
      </div>
    </Paper>
  )

}

export default Payment