import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import {
  Typography,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
  },
  left: {
    width: 270,
  },
  right: {},
  row: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    '& > h6': {
      fontWeight: 400
    }
  },
  key: {
    color: '#999'
  },
  button: {}
}));

function Footer (props) {

  const classes = useStyles()
  const { qtys, subtotal, currency, disabled, handleClick } = props

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <div className={classes.row}>
          <Typography variant="h6" className={classes.key}>Quatities:</Typography>
          <Typography variant="h6">{qtys}</Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="h6" className={classes.key}>Shipping:</Typography>
          <Typography variant="h6">To be confirmed</Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="h6" className={classes.key}>Total:</Typography>
          <Typography variant="h6">{currency.code}{subtotal.toFixed(2)}</Typography>
        </div>
      </div>
      <div className={classes.right}>
        <Button
          variant="contained"
          color="primary"
          disabled={!subtotal || disabled}
          className={classes.button}
          onClick={handleClick}
        >
          PROCEED TO CHECKOUT
        </Button>
      </div>
    </div>
  )

}

const mapStateToProps = state => ({
  caches: state.caches.caches,
  currency: state.global.currency
})

const connectedFooter = connect(mapStateToProps)(Footer)

export default connectedFooter