import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import browserHistory from 'common/history';
import {
  Breadcrumbs,
  Paper,
  Divider,
  Link,
} from '@material-ui/core';

import { EmptyList } from '../Common';
import { Header, Body, Footer } from './components';
import { updateCart, removeItem, alert, clearCart } from 'store/actions';
import { ordersService } from 'store/services';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    width: theme.content.width,
    margin: '0 auto',
    flex: 1,
  },
  content: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  body: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function PurchaseList (props) {

  const classes = useStyles()
  const { caches, currencyList, alert, clearCart } = props
  const qty = caches.reduce((cur, next) => cur + parseInt(next.qty), 0)
  const subtotal = caches.reduce((cur, next) => cur + parseInt(next.qty) * next.salesPrice, 0)

  const [saving, setSaving] = useState(false)

  const handleCreateOrder = ev => {
    setSaving(true)
    alert({ type: 'info', message: 'Sending data, please wait..' })
    ordersService.createOrder({
      qty,
      subtotal,
      items: caches
    })
      .then(res => {
        if (res.status === 0 && res.data.orderID) {
          setSaving(false)
          alert({ type: 'success', message: 'Your order is created successfully.' })
          clearCart()
          browserHistory.push(`/checkout/${res.data.orderID}`)
        } else {
          return Promise.reject()
        }
      })
      .catch(err => {
        setSaving(false)
        alert({ type: 'error', message: 'something went wrong :(, please try again..' })
      })
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/products">
          Purchase List
        </Link>
      </Breadcrumbs>
      {
        caches.length ? (
          <Paper className={classes.content}>
            <Header/>
            <Body disabled={saving}/>
            <Divider/>
            <Footer
              qtys={qty}
              subtotal={subtotal}
              handleClick={handleCreateOrder}
              disabled={saving}/>
          </Paper>
        ) : (
          <EmptyList
            title="Your purchase list is still empty"
            link="/products"
            linkText="find products"
          />
        )
      }
    </div>
  )

}

const mapStateToProps = state => ({
  caches: state.caches.caches,
  currencyList: state.global.currencyList
})

const mapDispatchToProps = dispatch => ({
  updateCart: (index, item) => dispatch(updateCart(index, item)),
  removeItem: index => dispatch(removeItem(index)),
  alert: payload => dispatch(alert(payload)),
  clearCart: () => dispatch(clearCart())
})

const connectedPurchaseList = connect(mapStateToProps, mapDispatchToProps)(PurchaseList);

export default connectedPurchaseList;