import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Select, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    paddingLeft: 8
  }
}));

const CustomSelect = props => {
  const { className, options, name, value, onChange, defaultValue, ...rest } = props;

  const classes = useStyles();

  return (
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Select
        className={classes.input}
        label="Select State"
        onChange={onChange}
        required
        native
        disableUnderline
        // eslint-disable-next-line react/jsx-sort-props
        inputProps={{
          name,
        }}
        value={value}
      >
        {defaultValue ? <option value="" disabled>{defaultValue || '请选择'}</option> : null}
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </Select>
    </Paper>
  );
};

CustomSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default CustomSelect;
