import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { Container } from '@material-ui/core';
import { LoadingWrap, Confirm } from 'components';
import { Topbar, Footer } from './components';
import { toogleSidebar } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 132,
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Main = props => {
  const { children, loading } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Confirm/>
      <Topbar/>
      <Container className={classes.content}>
        {children}
      </Container>
      <Footer />
      <LoadingWrap loading={loading}/>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => ({
  loading: state.global.loading,
  sidebarOpen: state.global.sidebarOpen
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Main);
