import React, { useState } from 'react'
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useStyles } from './style'
import { updateOrder, confirm, alert } from 'store/actions';

function ProductTable (props) {

  const classes = useStyles()
  const { order, className, currency } = props
  const [maxItems, setMaxItems] = useState(5)
  const showMore = maxItems < order.items.length

  const handleShowMore = ev => {
    let maxItems = showMore ? order.items.length : 5
    setMaxItems(maxItems)
  }

  return (
    <Table className={clsx(className, classes.root)}>
      <TableHead className={classes.root}>
        <TableRow>
          <TableCell className={classes.miniCell}>No.</TableCell>
          <TableCell className={classes.mainCell} colSpan={5}>
            <div className={classes.flex}>
              <div className={classes.flexGrow3}>Product Name</div>
              <div className={classes.flexGrow1}>Quantities</div>
              <div className={classes.flexGrow1}>Price</div>
              <div className={classes.flexGrow1}>Subtotal</div>
              <div className={classes.flexGrow1}>Reminder</div>
            </div>
          </TableCell>
          <TableCell className={classes.cell}>Total Quantities</TableCell>
          <TableCell className={classes.cell}>Amount</TableCell>
          <TableCell className={classes.cell}>Seller Message</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          order.items.slice(0, maxItems).map((item, index) => (
            <TableRow key={item.id}>
              <TableCell className={clsx(classes.miniCell, classes.verticalMiddle)}>{index + 1}</TableCell>
              <TableCell className={classes.mainCell} colSpan={5}>
                <div className={classes.flex}>
                  <div className={classes.flexGrow1}>
                    <img
                      src={item.img + '?imageView2/1/w/60/h/60/ignore-error/1'}
                      alt=""
                    />
                  </div>
                  <div className={clsx(classes.flex, classes.flexColumn, classes.justifyBetween, classes.flexGrow2)}>
                    <div>{item.name}</div>
                    <div>{item.spec}</div>
                  </div>
                  <div className={clsx(classes.flex, classes.flexGrow1)}>
                    <Typography variant="subtitle2">× {item.qty}</Typography>
                  </div>
                  <div className={classes.flexGrow1}>
                    <Typography variant="subtitle2">{currency.code}{item.salesPrice.toFixed(2)}</Typography>
                  </div>
                  <div className={classes.flexGrow1}>
                    <Typography variant="subtitle2">{currency.code}{(item.qty * item.salesPrice).toFixed(2)}</Typography>
                  </div>
                  <div className={classes.flexGrow1}>
                    {item.remark}
                  </div>
                </div>
              </TableCell>
              {
                index === 0 ?
                  (
                    <>
                      <TableCell
                        rowSpan={order.items.length}
                      >{order.qty}</TableCell>
                      <TableCell
                        rowSpan={order.items.length}
                      >{currency.code}{order.subtotal}</TableCell>
                      <TableCell
                        rowSpan={order.items.length}
                      >{order.sellerRemark}</TableCell>
                    </>
                  )
                  : null
              }
            </TableRow>
          ))
        }
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={8} className={classes.textCenter}>
            <Button
              onClick={handleShowMore}
              className={classes.textButton}
              endIcon={showMore ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
            >{showMore ? 'SHOW MORE' : 'SHOW LESS'}</Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}

const mapStateToProps = state => ({
  order: state.orders.order,
  currency: state.global.currency
})

const mapDispatchToProps = dispatch => ({
  updateOrder: option => dispatch(updateOrder(option)),
  confirm: message => dispatch(confirm(message)),
  alert: payload => dispatch(alert(payload))
})

const connectedProductTable = connect(mapStateToProps, mapDispatchToProps)(ProductTable)

export default connectedProductTable