import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Paper,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default function Container (props) {

  const classes = useStyles()
  const { title, subtitle, children } = props

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">{title}</Typography>
      { subtitle ? <Typography variant="body2">{subtitle}</Typography> : null }
      {children}
    </Paper>
  )
}