import { globalConst } from '../constants';
import { userService, adsService } from '../services';
import { alert } from './'

export function showLoading () {
  return {
    type: globalConst.SHOW_LOADING
  }
}

export function closeLoading () {
  return {
    type: globalConst.CLOSE_LOADING
  }
}

export function toogleSidebar (open) {
  return {
    type: open ? globalConst.OPEN_SIDEBAR : globalConst.CLOSE_SIDEBAR
  }
}

export function sendMessage (body) {

  return dispatch => {

    userService.sendMessage(body)
      .then(res => {
        if (res.status === 0) {
          dispatch(sendSuccess())
          dispatch(alert({ type: 'success', message: 'Your message has been sent successfully, we will reply in 48 hours' }))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        console.error(err)
        dispatch(alert({ type: 'error', message: 'Something is wrong, please try again :(' }))
      })
  }

  function sendSuccess () {
    return {
      type: globalConst.SEND_MESSAGE,
    }
  }
}

export function fetchAd (id) {

  return dispatch => {

    adsService.fetchAd(id)
      .then(res => {
        if (res.status === 0 && res.data) {
          dispatch(fetchAdSuccess(res.data || {}))
        }
      })
  }

  function fetchAdSuccess (ad) {
    return {
      type: globalConst.GET_AD,
      ad
    }
  }
}