/* eslint-disable no-mixed-operators */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link, Grid, Typography, IconButton } from '@material-ui/core';

import { fetchHomeProducts } from 'store/actions';
import { ProductItem } from './components';
import { Carousel } from '..';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 84
  },
  reverse: {
    flexDirection: 'row-reverse'
  },
  primaryProd: {
    
  },
  prodList: {
    
  },
  carouselBtn: {
    top: -64,
    position: 'absolute',
  },
  nextBtn: {
    right: -16,
  },
  prevBtn: {
    right: 32
  },
  prodBody: {
    position: 'relative',
    padding: theme.spacing(2),
    border: '1px solid #e5e5e5'
  },
  prodHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleGroup: {
    height: 45,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bolder',
    position: 'relative',
    zIndex: 10,
  },
  subtitle: {
    fontWeight: 800,
    fontSize: 60,
    position: 'absolute',
    left: -3,
    bottom: -16,
    color: '#ccc'
  },
  categories: {
    width: '75%',
    display: 'flex'
  },
  headerLink: {
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  marginRight: {
    marginRight: theme.spacing(2),
  }
}));

const placeholder = [...Array(8).keys()].map(key => ({id: key}))

const Products = props => {

  const classes = useStyles();
  const { title, data, currency, fetchProducts, options } = props
  const products = data['recommend'] && data['recommend'].data || placeholder

  const subtitle = title.charAt(0)

  const PrevArrow = props => {
    const { onClick } = props
    return (
      <IconButton className={clsx(classes.prevBtn, classes.carouselBtn)} onClick={onClick}>
        <ArrowLeftIcon/>
      </IconButton>
    )
  }

  const NextArrow = props => {
    const { onClick } = props
    return (
      <IconButton className={clsx(classes.nextBtn, classes.carouselBtn)} onClick={onClick}>
        <ArrowRightIcon/>
      </IconButton>
    )
  }

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    prevArrow: <PrevArrow/>,
    nextArrow: <NextArrow/>,
  }

  useEffect(() => {
    fetchProducts('recommend', options)
  }, [options, fetchProducts])

  return (
    <div className={classes.root}>
      <div className={classes.prodHeader}>
        <div className={classes.titleGroup}>
          <Typography variant="h3" className={classes.title}>{title}</Typography>
          <div className={classes.subtitle}>{subtitle}</div>
        </div>
      </div>
      <div className={classes.prodBody}>
        <div className={classes.carouselContainer}>
          <Carousel settings={settings}>
            {
              products.map(prod => <ProductItem product={prod} key={prod.id} className={classes.marginRight}/>)
            }
          </Carousel>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  data: state.products.home,
  currency: state.global.currency
})

const mapDispatchToProps = dispatch => ({
  fetchProducts: (key, options) => dispatch(fetchHomeProducts(key, options)),
})

const connectedProducts = connect(mapStateToProps, mapDispatchToProps)(Products)

export default connectedProducts
