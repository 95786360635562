import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Collapse,
  Breadcrumbs
} from '@material-ui/core';

import { sendMessage } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    width: theme.content.width,
    margin: '0 auto'
  },
  container: {
    marginTop: 45
  },
  side: {
    paddingLeft: theme.spacing(4),
  },
  form: {
    marginTop: theme.spacing(6),
  },
  sideBlock: {
    marginBottom: theme.spacing(6),
  },
  sideContent: {
    marginTop: theme.spacing(3),
  },
  row: {
    display: 'flex',
    lineHeight: '24px',
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  response: {
    marginTop: theme.spacing(4),
  }
}));

const schema = {
  fullname: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  subject: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  message: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const Contact = props => {

  const classes = useStyles();
  const { hasSentMessage, sendMessage } = props;
  const [open, setOpen] = useState(false)

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    setOpen(hasSentMessage)
  }, [hasSentMessage])

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSave = ev => {
    formState.isValid && sendMessage(formState.values)
    setFormState(formState => ({
      values: {},
      touched: {}
    }));
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          Home
        </Link>
        <Typography color="textPrimary">Contact us</Typography>
      </Breadcrumbs>
      <Grid
        container
        spacing={4}
        className={classes.container}
      >
        <Grid
          item
          sm={8}
          xs={12}
        >
          <div className="get_in_touch">
            <Typography variant="h3">Get in Touch</Typography>
            <Typography variant="subtitle2">Leave us a message(required fields are marked with *)</Typography>
            <Collapse in={open} className={classes.response}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >Your message has been sent successfully, we will reply in 48 hours.</Alert>
            </Collapse>
            <div className={classes.form}>
              <form action="#" id="contact_form" className="contact_form">
                <Grid
                  container
                  spacing={4}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      error={hasError('fullname')}
                      placeholder="Your fullname"
                      fullWidth
                      helperText={
                        hasError('fullname') ? formState.errors.fullname[0] : null
                      }
                      label="Full Name*"
                      name="fullname"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.fullname || ''}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      error={hasError('email')}
                      fullWidth
                      helperText={
                        hasError('email') ? formState.errors.email[0] : null
                      }
                      label="Email*"
                      name="email"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.email || ''}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      error={hasError('subject')}
                      fullWidth
                      helperText={
                        hasError('subject') ? formState.errors.subject[0] : null
                      }
                      label="Subject*"
                      name="subject"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.subject || ''}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      error={hasError('message')}
                      fullWidth
                      multiline
                      rows={6}
                      helperText={
                        hasError('message') ? formState.errors.message[0] : null
                      }
                      label="Message*"
                      name="message"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.message || ''}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                  onClick={handleSave}
                >
                  SEND MESSAGE
                </Button>
              </form>
            </div>
          </div>
        </Grid>
        <Grid
          item
          sm={4}
          xs={12}
        >
          <div className={classes.side}>
            <div className={classes.sideBlock}>
              <Typography variant="h4">Marketing</Typography>
              <ul className={classes.sideContent}>
                <Typography noWrap variant="subtitle2" className={classes.row}>Email: monica@diascubes.com</Typography>
                <Typography noWrap variant="subtitle2" className={classes.row}>Phone: +86 132 8298 8238</Typography>
                <Typography noWrap variant="subtitle2" className={classes.row}>Skype: +86 132 8298 8238</Typography>
                <Typography noWrap variant="subtitle2" className={classes.row}>Whatsapp: +86 132 8298 8238</Typography>
              </ul>
            </div>
            <div className={classes.sideBlock}>
              <Typography variant="h4">Shippiing & Returns</Typography>
              <ul className={classes.sideContent}>
                <Typography variant="subtitle2" className={classes.row}>Phone: +86 189 4699 9091</Typography>
                <Typography variant="subtitle2" className={classes.row}>Email: walle.wwh@gmail.com</Typography>
              </ul>
            </div>
            <div className={classes.sideBlock}>
              <Typography variant="h4">Information</Typography>
              <ul className={classes.sideContent}>
                <Typography variant="subtitle2" className={classes.row}>Email: info@diascubes.com</Typography>
              </ul>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  hasSentMessage: state.global.hasSentMessage,
})

const mapDispatchToProps = dispatch => ({
  sendMessage: message => dispatch(sendMessage(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
