import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress
} from '@material-ui/core';

import { fetchUser, patchUser, alert } from 'store/actions'
import { productsService } from 'store/services'

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 100,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  input: {
    position: 'absolute',
    visibility: 'hidden'
  },
}));

const AccountProfile = props => {

  const { className, user, loadingUser, patchUser, fetchUser } = props;

  const classes = useStyles();
  const defaultAvatar = 'http://static.diascubes.com/admin/images/products/product_5.png';

  const [uploading, setUploading] = useState(true)
  const [rate, setRate] = useState(0)

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  useEffect(() => {
    setUploading(loadingUser)
  }, [loadingUser])

  useEffect(() => {
    const requiredKeys = ['avatar', 'firstname', 'lastname', 'mobile', 'country', 'city', 'facebook']
    const fields = requiredKeys.filter(key => !!user[key])
    const rate = parseInt(fields.length / requiredKeys.length * 100)
    setRate(rate)
  }, [user])

  function handleUpload (ev) {

    const files = ev.target.files
    const formData = new FormData()
    formData.append('name', user.userName)
    formData.append('file', files[0])
    
    setUploading(true)
    productsService.upload(formData)
      .then(res => {
        if (res.status === 0 && res.data.success) {
          patchUser({ avatar: res.data.url })
          setUploading(false)
        } else {
          return Promise.reject(new Error('upload failed :(, please try again.'))
        }
      })
      .catch(err => {
        setUploading(false)
        alert({type: 'error', message: 'upload failed :(, please try again.'})
      })
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h2"
            >
              {user.userName}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="subtitle2"
            >
              { user.email }
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              Since {moment(user.createdAt).format('MM-DD-YYYY')}
            </Typography>
          </div>
          {
            uploading
              ? <Skeleton animation="wave" variant="circle" width={100} height={100} className={classes.avatar}/>
              : (<Avatar
                  className={classes.avatar}
                  src={user.avatar || defaultAvatar}
                />)
          }
        </div>
        <div className={classes.progress}>
          <Typography variant="body1">Profile Completeness: {rate}%</Typography>
          <LinearProgress
            value={rate}
            variant="determinate"
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleUpload}
        />
        <label htmlFor="contained-button-file" className={classes.label}>
          <Button
            className={classes.uploadButton}
            color="primary"
            variant="text"
            component="span"
          >
            Upload picture
          </Button>
        </label>
      </CardActions>
    </Card>
  );
};

AccountProfile.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.user.user,
  loadingUser: state.user.loadingUser
})

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUser()),
  patchUser: (userInfo) => dispatch(patchUser(userInfo)),
})

const connectedAccountProfile = connect(mapStateToProps, mapDispatchToProps)(AccountProfile)

export default connectedAccountProfile
