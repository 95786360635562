import React from 'react';
import ProtectedRoute from '../ProtectedRoute';
import PropTypes from 'prop-types';

export const ProtectedRouteWithLayout = props => {
  const { loggedIn, loggingIn, layout: Layout, component: Component, ...rest } = props;

  const LayoutComponent = props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )
  return (
    <ProtectedRoute
      {...rest}
      loggedIn={loggedIn}
      loggingIn={loggingIn}
      component={LayoutComponent}
    />
  );
};

ProtectedRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};
