import { brandsConst } from '../constants';
import { cubeBrands, cubeCategories } from './brands';

const defaultState = {
  loading: false,
  brands: [],
  categories: [],
  error: ''
}

export function brands (state = defaultState, action) {
  switch (action.type) {
    case brandsConst.GET_REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case brandsConst.GET_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        brands: action.brands
      });
    case brandsConst.GET_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    case brandsConst.GET_BRANDS:
      return Object.assign({}, state, {
        brands: cubeBrands,
        categories: cubeCategories
      });
    case brandsConst.GET_CATEGORIES:
      return Object.assign({}, state, {
        categories: cubeCategories
      });
    default:
      return state
  }
}