import * as React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import { ProductTable } from './components'
import { Container } from '..'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2)
    },
  })
)

export default function OrderTable (props) {

  const classes = useStyles()
  const { order } = props

  return (
    <Container title="Products">
      <ProductTable order={order} className={classes.root}/>
    </Container>
  )
}