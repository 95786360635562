import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton'
import ErrorIcon from '@material-ui/icons/Error'
import browserHistory from 'common/history'
import {
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import qs from 'query-string'

import { register, verifyLink } from 'store/actions'
import { RegisterForm } from './components'
import { AuthAd } from '../Common'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(http://static.diascubes.com/admin/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  error: {
    paddingLeft: 100,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  errorText: {
    marginLeft: theme.spacing(2),
  },
  loadding: {
    flexGrow: 1,
    paddingLeft: 100,
    paddingRight: 100,
  },
  loaddingBar: {
    height: 45,
    width: '100%',
    marginTop: theme.spacing(2),
  }
}));

const Verify = props => {

  const { location, validLink, verifyingLink, registerSuccess, verifyLink, register } = props;
  const { email, key } = qs.parse(location.search)

  const classes = useStyles();

  const handleBack = () => {
    browserHistory.replace('/')
  };

  const handleSignUp = (form) => {
    register(form)
  };

  useEffect(() => {
    verifyLink({
      email,
      key
    })
  }, [email, key, verifyLink])

  if (registerSuccess) return <Redirect to={{ pathname: '/' }}/>

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <AuthAd/>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
              { verifyingLink ? <Typography variant="h6">Loading data, please wait...</Typography> : null}
            </div>
            <div className={classes.contentBody}>
              {
                verifyingLink ? (
                  <div className={classes.loadding}>
                    {
                      [...new Array(5).keys()].map(i => (
                        <Skeleton
                          key={i}
                          variant="rect"
                          animation="wave"
                          className={classes.loaddingBar}/
                        >)
                      )
                    }
                  </div>
                ) : validLink ? (
                  <RegisterForm
                    email={email}
                    token={key}
                    handleSubmit={handleSignUp}
                  />
                ) : (
                  <Typography className={classes.error} variant="subtitle1">
                    <ErrorIcon color="error"/><span className={classes.errorText}>This link is no longer valid :(</span>
                  </Typography>
                )
              }
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Verify.propTypes = {
  history: PropTypes.object
};

const mapStateToProps = state => ({
  emailSent: state.auth.emailSent,
  validLink: state.auth.validLink,
  verifyingLink: state.auth.verifyingLink,
  registerSuccess: state.auth.registerSuccess,
})

const mapDispatchToProps = dispatch => ({
  register: body => dispatch(register(body)),
  verifyLink: params => dispatch(verifyLink(params))
})

const connectedSignUp = connect(mapStateToProps, mapDispatchToProps)(withRouter(Verify))

export default connectedSignUp
