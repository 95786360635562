import { userConst } from '../constants';

const defaultState = {
  loadingUser: false,
  loadingAddressList: false,
  user: {},
  error: '',
  addressList: []
}

export function user (state = defaultState, action) {
  switch (action.type) {
    case userConst.USER_REQUEST:
      return Object.assign({}, state, {
        loadingUser: true
      })
    case userConst.USER_SUCCESS:
      return Object.assign({}, state, {
        loadingUser: false,
        user: action.user
      });
    case userConst.USER_FAILURE:
      return Object.assign({}, state, {
        loadingUser: false,
        error: action.error
      });
    case userConst.GET_ADDRESS_LIST_REQUEST:
      return Object.assign({}, state, {
        loadingAddressList: true,
      });
    case userConst.GET_ADDRESS_LIST:
      return Object.assign({}, state, {
        loadingAddressList: false,
        addressList: action.addressList
      });
    case userConst.PATCH_REQUEST:
      return Object.assign({}, state, {
        loadingUser: true,
      });
    case userConst.PATCH_USER_SUCCESS:
      let _user = Object.assign({}, state.user, action.user)
      return Object.assign({}, state, {
        loadingUser: false,
        user: _user
      });
    default:
      return state
  }
}