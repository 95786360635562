import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '30px 0'
  },
  brandBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  brand: {
    flex: 1,
    textAlign: 'center',
    padding: 30,
    fontSize: 30,
    fontWeight: 'bolder',
    color: '#999'
  }
}));

const BrandList = props => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.brandBox}>
        <div className={classes.brand}>
          <img src="http://images2.diascubes.com/1603172710944-Gan.jpg" alt=""/>
        </div>
        <div className={classes.brand}>
          <img src="http://images2.diascubes.com/1603172710948-MoYu.jpg" alt=""/>
        </div>
        <div className={classes.brand}>
          <img src="http://images2.diascubes.com/1603180138595-QY.jpg" alt=""/>
        </div>
        <div className={classes.brand}>
          <img src="http://images2.diascubes.com/1603172711008-Shengshou.jpg" alt=""/>
        </div>
        <div className={classes.brand}>
          <img src="http://images2.diascubes.com/1603172711006-YJ.jpg" alt=""/>
        </div>
        <div className={classes.brand}>
          <img src="http://images2.diascubes.com/1666142744517-DS-LOGO.png?imageView2/1/w/120/h/120/ignore-error/1" alt=""/>
        </div>
      </div>
    </div>
  );
};

export default BrandList
