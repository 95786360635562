export const quotaConst = {
  // 获取用户
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  // 获取报价
  GET_QUOTA_REQUEST: 'GET_QUOTA_REQUEST',
  GET_QUOTA_SUCCESS: 'GET_QUOTA_SUCCESS',
  GET_QUOTA_FAILURE: 'GET_QUOTA_FAILURE',

  // 修改报价
  PATCH_REQUEST: 'PATCH_REQUEST',
  PATCH_SUCCESS: 'PATCH_SUCCESS',
  PATCH_FAILURE: 'PATCH_FAILURE',

  // 修改用户利润率和汇率
  MUTATE_USER: 'MUTATE_USER'
}