import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Paper,
  Link,
  Typography,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  placeholder: {
    marginTop: theme.spacing(3),
    padding: '60px 0'
  },
  empty: {
    width: 570,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center'
  },
  emptyInfo: {
    marginLeft: theme.spacing(4),
  },
  link: {
    marginTop: theme.spacing(1),
  }
}));

const EmptyList = props => {
  
  const classes = useStyles();
  const bgUrl = 'http://images2.diascubes.com/2253239_1148900260.png-2253239_1148900260.png'
  const { title, link, linkText } = props

  return (
    <Paper className={classes.placeholder}>
      <div className={classes.empty}>
        <img src={bgUrl} alt=""/>
        <div className={classes.emptyInfo}>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="subtitle2" className={classes.link}>
            <Link
              component={RouterLink}
              to={link}
              variant="h6"
            >
              <Button
                size="small"
                color="primary"
              >{linkText}</Button>
            </Link>
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

export default EmptyList
