import React from 'react';
import { makeStyles } from '@material-ui/styles';

import SecurityIcon from '@material-ui/icons/Security';
import PhoneIcon from '@material-ui/icons/Phone';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: `${theme.spacing(4)}px 0 0`
  },
  serviceItem: {
    width: '30%',
    paddingRight: theme.spacing(2),
    boxSizing: 'border-box',
    border: '1px solid #e5e5e5',
    padding: 20
  },
  serviceBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  serviceContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12
  },
  serviceSubtitle: {
    fontSize: 13,
    color: '#999'
  }
}));

const Services = props => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.serviceItem}>
        <div className={classes.serviceBox}>
          <SecurityIcon fontSize="large"/>
          <div className={classes.serviceContent}>
            <Typography variant="button">Quality guarantee</Typography>
            <Typography className={classes.serviceSubtitle}>100% Original</Typography>
          </div>
        </div>
      </div>
      <div className={classes.serviceItem}>
        <div className={classes.serviceBox}>
          <PhoneIcon fontSize="large"/>
          <div className={classes.serviceContent}>
            <Typography variant="button">Quick response</Typography>
            <Typography className={classes.serviceSubtitle}>7x24 online</Typography>
          </div>
        </div>
      </div>
      <div className={classes.serviceItem}>
        <div className={classes.serviceBox}>
          <AttachMoneyIcon fontSize="large"/>
          <div className={classes.serviceContent}>
            <Typography variant="button">Competitive price</Typography>
            <Typography className={classes.serviceSubtitle}>Top level distributor</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services
