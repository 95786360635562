import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
// import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import browserHistory from './common/history'

// import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'

import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import rootReducer from './store/reducers'
import { auth } from './store/actions'
import { Alert } from 'components'

const loggerMiddleware = createLogger()

const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware,
    loggerMiddleware
  )
)

// Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
//   draw: chartjs.draw
// });

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {

  useEffect(() => {
    store.dispatch(auth())
  })

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router history={browserHistory}>
          <Routes/>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App
