import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Paper, Typography } from '@material-ui/core';

import { fetchHomeProducts } from 'store/actions'
import { Carousel } from '..'
import { ProductItem } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(7)
  },
  title: {
    marginBottom: theme.spacing(1),
    fontFamily: 'Nunito',
    fontSize: 30,
    color: '#262e31',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: theme.spacing(3),
    '&::after': {
      content: '""',
      display: 'block',
      width: 70,
      height: 3,
      margin: `${theme.spacing(3)}px auto 0`,
      background: theme.palette.primary.main,
    }
  },
  body: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 30
  },
  product: {
    width: '23%',
    borderRadius: 0,
    boxShadow: 'none',
  },
}));

const moduleName = 'new'
const placeholder = [...Array(4).keys()].map(key => ({id: key}))

const NewArrivals = props => {

  const classes = useStyles();
  const { products = placeholder, currency, fetchProducts } = props
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
  }

  useEffect(() => {
    fetchProducts(moduleName, {
      tags: 2, pageSize: 4
    })
  }, [fetchProducts])

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>New Arrivals</Typography>
      <div className={classes.body}>
        {
          products.map(product => (
            <ProductItem
              product={product}
              key={product.id}
              className={classes.product}
              currencyMark={currency.code}
            />
          ))
        }
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  products: state.products.home[moduleName] && state.products.home[moduleName].data,
  currency: state.global.currency
})

const mapDispatchToProps = dispatch => ({
  fetchProducts: (key, options) => dispatch(fetchHomeProducts(key, options)),
})

const connectedProducts = connect(mapStateToProps, mapDispatchToProps)(NewArrivals)

export default connectedProducts
