import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button, TextField } from '@material-ui/core';
import { sendMessage } from 'store/actions';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  media: {
    paddingTop: theme.spacing(2),
    height: 80,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto'
    }
  },
  content: {
    padding: theme.spacing(1, 0)
  },
  actions: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'center'
  }
}));

const schema = {
  subject: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  message: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const QuickAsk = props => {

  const { className, user, sendMessage } = props;
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const classes = useStyles();

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSave = ev => {
    const { firstname, lastname, userName, email } = user
    let fullname = userName
    if (firstname && lastname) {
      fullname = firstname + ' ' + lastname
    }
    formState.isValid && sendMessage(Object.assign(formState.values, { fullname, email }))
    setFormState(formState => ({
      values: {},
      touched: {}
    }));
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  return (
    <div className={clsx(classes.root, className)}>
      <Typography
        align="center"
        gutterBottom
        variant="h4"
      >
        Leave a message
      </Typography>
      <div className={classes.content}>
        <TextField
          className={classes.textField}
          error={hasError('subject')}
          fullWidth
          helperText={
            hasError('subject') ? formState.errors.subject[0] : null
          }
          label="Subject*"
          name="subject"
          margin="dense"
          onChange={handleChange}
          type="text"
          value={formState.values.subject || ''}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className={classes.textField}
          error={hasError('message')}
          fullWidth
          multiline
          rows={6}
          helperText={
            hasError('message') ? formState.errors.message[0] : null
          }
          label="Message*"
          name="message"
          margin="dense"
          onChange={handleChange}
          type="text"
          value={formState.values.message || ''}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div className={classes.actions}>
        <Button
          color="primary"
          component="button"
          variant="contained"
          onClick={handleSave}
        >
          Send Message
        </Button>
      </div>
    </div>
  );
};

QuickAsk.propTypes = {
  className: PropTypes.string
};


const mapStateToProps = state => ({
  user: state.auth.user,
  hasSentMessage: state.global.hasSentMessage,
})

const mapDispatchToProps = dispatch => ({
  sendMessage: message => dispatch(sendMessage(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuickAsk);
