import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx'
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Container } from '..'

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 12
  },
  flex: {
    display: 'flex',
  },
  center: {
    alignItems: 'center',
  },
  row: {
  '& > p': {
    marginRight: 10
  },
  marginBottom: 6,
},
}));


function OrderInfo (props) {

  const classes = useStyles();
  const { order } = props

  return (
    <Container title="Package Infomation">
      <div className={classes.content}>
        <div className={clsx(classes.row, classes.flex, classes.center)}>
          <Typography variant="body2">Actual Weight: </Typography>
          <Typography variant="subtitle2">{order.realWeight || 0} kg</Typography>
        </div>
        <div className={clsx(classes.row, classes.flex, classes.center)}>
          <Typography variant="body2">Actual Volumn: </Typography>
          <Typography variant="subtitle2">{order.realVolumn || 0} cm</Typography>
        </div>
        <div className={clsx(classes.row, classes.flex, classes.center)}>
          <Typography variant="body2">Tracking Number: </Typography>
          <Typography variant="subtitle2">{order.trackingNumber || ''}</Typography>
        </div>
        <div className={clsx(classes.row, classes.flex, classes.center)}>
          <Typography variant="body2">Packages No.: </Typography>
          <Typography variant="subtitle2">{order.packageNum || 0}</Typography>
        </div>
        <div className={clsx(classes.row, classes.flex, classes.center)}>
          <Typography variant="body2">Packing List: </Typography>
          {
            order.hasPackingList
              ? (
                <Link to={`/packingList/${order._orderId}`}>
                  view list >>
                </Link>
              ) : <Typography variant="subtitle2">No</Typography>
          }
          <Typography variant="subtitle2"></Typography>
        </div>
      </div>
    </Container>
  );
}

const mapStateToProps = state => ({
  order: state.orders.order,
})

const connectedOrderInfo = connect(mapStateToProps)(OrderInfo)

export default connectedOrderInfo
