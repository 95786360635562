import React, { useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Link,
  Typography
} from '@material-ui/core';

import { fetchAd } from 'store/actions'


const useStyles = makeStyles(theme => ({
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.white,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  quoteText: {
    color: theme.palette.text,
    fontWeight: 300
  },
  title: {
    marginTop: theme.spacing(3)
  },
  img: {
    width: 400,
    height: 400,
    margin: '0 auto',
    '& > img': {
      display: 'block',
      width: '100%'
    }
  },
  link: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
}));

const AuthAd = props => {

  const { ad = {}, fetchAd } = props;
  
  const classes = useStyles();
  
  useEffect(() => {
    const authAdId = 1
    fetchAd(authAdId)
  }, [fetchAd]);

  return (
    <Grid
      className={classes.quoteContainer}
      item
      lg={5}
    >
      <div className={classes.quote}>
        <div className={classes.quoteInner}>
          <div className={classes.img}>
            <img src={ad.img} alt=""/>
          </div>
          <Typography
            className={classes.quoteText}
            variant="h3"
          >
            {ad.title}
          </Typography>
          <Link
            className={classes.link}
            component={RouterLink}
            to={ad.url || ''}
            variant="h6"
          >
            Learn More
          </Link>
        </div>
      </div>
    </Grid>
  );
};

const mapStateToProps = state => ({
  ad: state.global.ad
})

const mapDispatchToProps = dispatch => ({
  fetchAd: id => dispatch(fetchAd(id)),
})

const connectedAuthAd = connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthAd))

export default connectedAuthAd
