import { confirmConst } from '../constants';

export function confirm (state = { open: false }, action) {
  switch (action.type) {
    case confirmConst.OPEN_CONFIRM:
      return Object.assign({}, state, {
        open: true,
        message: action.message,
        promise: action.promise
      })
    case confirmConst.CLOSE_CONFIRM:
      return Object.assign({}, state, {
        open: false,
      })
    default:
      return state
  }
}