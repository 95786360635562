import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontWeight: 'bolder'
  },
  logo: {
    verticalAlign: 'top',
    borderRadius: '50%',
    marginRight: theme.spacing(2),
  },
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <div className={classes.logoWrapper}>
            <img
              alt="Logo"
              className={classes.logo}
              src="http://images2.diascubes.com/22.jpg-22.jpg?imageView2/1/w/36/h/36/ignore-error/1"
            />
            <Typography variant="h4" color="inherit">Yiwu MoYu E-Commerce Co., Ltd</Typography>
          </div>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
