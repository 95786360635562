import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom'
import {
  Breadcrumbs,
  Typography,
  Paper,
  Grid,
  Divider,
} from '@material-ui/core';

import { fetchProduct } from 'store/actions'
import { Images, Info, Skus } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    width: theme.content.width,
    margin: '0 auto',
  },
  content: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3)
  },
  description: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    '& img': {
      maxWidth: 800
    }
  }
}));

const Detail = (props) => {
  const classes = useStyles();

  const { product, loggingIn } = props
  const { fetchProduct } = props
  const { id } = useParams()

  const createMarkup = html => {
    return {
      __html: html
    }
  }

  useEffect(() => {
    fetchProduct(id, loggingIn)
  }, [id, fetchProduct, loggingIn])

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/products">
          Products List
        </Link>
        <Typography color="textPrimary">{product.modelName}</Typography>
      </Breadcrumbs>
      <Paper className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Images cover={product.coverImage} images={product.images}/>
          </Grid>
          <Grid item xs={6}>
            <Info product={product}/>
            <Divider/>
            <Skus product={product}/>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.description}>
        <Typography variant="body1">
          <div dangerouslySetInnerHTML={createMarkup(product.description)}></div>
        </Typography>
      </Paper>
    </div>
  );
};

const mapStateToProps = state => ({
  loggingIn: state.auth.loggingIn,
  product: state.product.product,
})

const mapDispatchToProps = dispatch => ({
  fetchProduct: id => dispatch(fetchProduct(id)),
})

const connectedDetail = connect(mapStateToProps, mapDispatchToProps)(Detail)

export default connectedDetail
