import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import {
  Typography,
  Collapse,
  Button,
  IconButton,
  Link,
  Divider
} from '@material-ui/core';

import { removeItem } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    display: 'flex',
    background: '#f9f9f9',
    padding: `${theme.spacing(1)}px 0`,
    alignItems: 'center',
    position: 'relative'
  },
  left: {
    flex: 1,
    padding: `0 ${theme.spacing(4)}px`,
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #e5e5e5',
    '& > *': {
      padding: `0 ${theme.spacing(2)}px`,
    },
    '& > *:first-child': {
      borderRight: '1px solid #e5e5e5',
    }
  },
  right: {
    padding: `0 ${theme.spacing(3)}px`
  },
  list: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: '100%',
    left: 0,
    bottom: '100%',
    background: '#fff',
    zIndex: theme.zIndex.drawer,
    overflowY: 'auto',
  },
  listInner: {
    maxHeight: 440,
  },
  visible: {
    boxShadow: '0 -3px 3px -3px #d5d5d5',
    border: '1px solid #d5d5d5',
  },
  skuRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    '&:first-child': {
      border: 0
    },
    borderTop: '1px solid #e5e5e5'
  },
  placeholder: {
    width: '10%'
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    padding: `0 ${theme.spacing(1)}px`,
  },
  numbers: {
    width: '10%',
    marginRight: theme.spacing(2)
  },
  stress: {
    color: '#333',
    fontWeight: 500
  },
  footer: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  divider: {
    margin: `${theme.spacing(1)}px 0`,
  }
}));

const Purchase = (props) => {

  const classes = useStyles();
  const { caches, currency, removeItem } = props
  const qtys = caches.reduce((cur, next) => cur + parseInt(next.qty), 0)
  const subtotal = caches.reduce((cur, next) => cur + parseInt(next.qty) * next.salesPrice, 0)

  const [open, setOpen] = useState(false)

  const handleRemove = index => {
    removeItem(index)
  }
  
  return (
    <ClickAwayListener onClickAway={ev => setOpen(false)}>
      <div className={classes.root}>
        <div className={classes.left}>
          <Typography variant="h5">{qtys} pcs</Typography>
          <Typography variant="h5">{currency.code}{subtotal.toFixed(2)}</Typography>
        </div>
          <div className={classes.right}>
            <Button
              size="small"
              onClick={ev => setOpen(!open)}
              endIcon={ open ? <ExpandMoreIcon/> : <ExpandLessIcon/> }
            >Purchase List</Button>
          </div>
          {
            caches.length ? (
              <div className={clsx(classes.list, { [classes.visible]: open })}>
                <Collapse in={open}>
                  <div className={classes.listInner}>
                    {
                      caches.map((cache, index) => (
                        <div className={classes.skuRoot} key={cache.id}>
                          <img src={cache.img + '?imageView2/1/w/60/h/60/ignore-error/1'} alt=""/>
                          <div className={classes.title}>
                            <Typography variant="subtitle2">{cache.modelName}</Typography>
                            <Typography variant="body2">{cache.spec}</Typography>
                          </div>
                          <Typography variant="subtitle2" className={clsx(classes.numbers, classes.stress)}>{currency.code}{cache.salesPrice}</Typography>
                          <Typography variant="subtitle2" className={classes.numbers}>x {cache.qty}</Typography>
                          <div className={classes.button}>
                            <IconButton
                              edge="start"
                              color="inherit"
                              onClick={ev => handleRemove(index)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </div>
                      ))
                    }
                    <Divider/>
                    <div className={classes.footer}>
                      <Link
                        component={RouterLink}
                        to="/purchase-list"
                        variant="h6"
                      >
                        PROCEED TO CHECKOUT
                      </Link>
                    </div>
                  </div>
                </Collapse>
              </div>
            ) : null
          }
      </div>
    </ClickAwayListener>
  );
};

const mapStateToProps = state => ({
  caches: state.caches.caches,
  currency: state.global.currency
})

const mapDispatchToProps = dispatch => ({
  removeItem: index => dispatch(removeItem(index)),
})

const connectedPurchase = connect(mapStateToProps, mapDispatchToProps)(Purchase)

export default connectedPurchase
