import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom'
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Cert } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: theme.content.width,
    margin: '0 auto'
  },
  container: {
    marginTop: 45
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  block: {
    paddingTop: theme.spacing(4),
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  carouselWrapper: {

  }
}))

export default function About (props) {

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          Home
        </Link>
        <Typography color="textPrimary">About us</Typography>
      </Breadcrumbs>
      <Typography className={classes.container} variant="body1" component="div">
        <Typography variant="h3">ABOUT US</Typography>

        <div className={classes.block}>
          <Typography variant="h5" className={classes.subtitle}>OVERVIIEW</Typography>
          <Typography variant="body1">
            Established in 2015 and based in Shanghai, Huang Weiwei,Dooruo International Trading (Shanghai) Co., Ltd is a professional foreign trade company, mainly engaged in the distribution of Magic Cube, 
            intellectual toys, and other toys.
            <br/>
            We have been in foreign trade business for more than 6 years and have served customers in more than 20 countries around the world.
            In 2018, we continued to expand our scale, established Yiwu Moyu Trading Co., Ltd. in Yiwu, Zhejiang, added domestic sales to our business, 
            and began to set up a 400m<sup>2</sup> warehouse in Shantou, Guangdong, where most of the cube manufacturers including Moyu, Qiyi, Yongjun, Shengshou, Yuxin etc are gathered.
          </Typography>
        </div>

        <div className={classes.block}>
          <Typography variant="h5" className={classes.subtitle}>OUR MISSION</Typography>
          <Typography variant="body1">
            Our goal is to provide high-quality products and services to global customers, and is committed to maintaining long-term and stable trade cooperation with global customers.
          </Typography>
        </div>

        <div className={classes.block}>
          <Typography variant="h5" className={classes.subtitle}>WHY CHOOSE US</Typography>
          <div className={classes.list}>
            <Typography variant="h6" className={classes.subtitle}>1. Supply Capacity</Typography>
            <div className={classes.content}>
              <Typography variant="body1">We are one of the largest Cube distributors in China, we maintains a close, stable and friendly cooperative relationship with most cube factories for more than 5 years.</Typography>
              <Typography variant="body1">We have our own warehouse in Shantou, less than an hour's drive from most of the cube factories.</Typography>
              <Typography variant="body1">
                Thus ensuring that we can keep update to the latest product information and can ensure that new products are shipped immediately after they are available.
              </Typography>
            </div>
            <Typography variant="h6" className={classes.subtitle}>2. Experienced foreign trade business manager</Typography>
            <div className={classes.content}>
              <Typography variant="body1">
                We have several trade managers with rich experience in foreign trade, which can efficiently handle various matters in the trade process for you.
              </Typography>
            </div>
            <Typography variant="h6" className={classes.subtitle}>3. Online order system</Typography>
            <div className={classes.content}>
              <Typography variant="body1">
                You can view and complete the ordering of products directly on this website, and you can check the progress of your order in real time.
                Even more, we will inform you about the new products information by email.
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.block}>
          <Typography variant="h6">Certificate of Authorization</Typography>
          <div className={classes.carouselWrapper}>
            <Cert/>
          </div>
        </div>
      </Typography>
    </div>
  )
}