import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import clsx from 'clsx'
import {
  Typography,
  IconButton,
  TextField,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton'

import styles from './styles';
import { updateOrder } from 'store/actions'
import { OrderItems } from './components'

const useStyles = makeStyles(theme => ({
  ...styles,
  body: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0`
  },
  input: {
    width: '80%'
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacing: {
    marginLeft: theme.spacing(2),
  },
  button: {
    textAlign: 'center'
  }
}));

function Products (props) {

  const classes = useStyles()
  const { order } = props
  const { updateOrder } = props
  const maxItems = 5

  const [open, setOpen] = useState(false)

  const handleChange = (index, sku, qty) => {
    let newItems = []
    let { items } = order
    qty = Math.max(1, qty)
    let newItem = Object.assign({}, items[index], { qty })
    if (index === 0) {
      newItems = newItems.concat(newItem, items.slice(1))
    } else if (index === items.length - 1) {
      newItems = newItems.concat(items.slice(0, -1), newItem)
    } else {
      newItems = newItems.concat(
        items.slice(0, index),
        newItem,
        items.slice(index + 1))
    }
    updateOrder({
      ...order,
      touchedItems: true,
      items: newItems
    })
  }

  const handleRemove = index => {
    let newItems = []
    let { items } = order
    if (index > -1) {
      if (index === 0) {
        newItems = newItems.concat(items.slice(1))
      } else if (index === items.length - 1) {
        newItems = newItems.concat(items.slice(0, -1))
      } else {
        newItems = newItems.concat(items.slice(0, index), items.slice(index + 1))
      }
      updateOrder({
        ...order,
        touchedItems: true,
        items: newItems
      })
    }
  }

  return (
    <div className={classes.body}>
      {
        order.items.length === 0 ?
        [...new Array(maxItems).keys()].map(i => (<Skeleton key={i} animation="wave" height={60} style={{ marginBottom: 8 }}/>))
        : order.items.slice(0, maxItems).map((sku, index) => (
          <div className={classes.row} key={sku.id}>
            <div className={classes.main}>
              <img src={sku.img + '?imageView2/1/w/60/h/60/ignore-error/1'} alt=""/>
              <div className={clsx(classes.spacing, classes.name)}>
                <Typography variant="subtitle2">{sku.name}</Typography>
                <Typography variant="body2">{sku.spec}</Typography>
              </div>
            </div>
            <div className={clsx(classes.spacing, classes.qty)}>
              <TextField
                className={classes.input}
                label="Qty"
                margin="dense"
                name="qty"
                type="number"
                onChange={ev => handleChange(index, sku, ev.target.value)}
                required
                value={sku.qty}
                variant="outlined"
                inputProps= {{
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            </div>
            <Typography variant="subtitle2" className={clsx(classes.spacing, classes.total)}>{(sku.qty * sku.salesPrice).toFixed(2)}</Typography>
            <div className={classes.action}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={ev => handleRemove(index)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        ))
      }
      {
        order.items.length > maxItems ? (
          <div className={classes.button}>
            <Button onClick={ev => setOpen(true)} className={classes.textButton} endIcon={<ExpandMoreIcon/>}>show all products</Button>
          </div>
        ) : null
      }
      <OrderItems
        open={open}
        items={order.items}
        handleClose={setOpen}
        handleRemove={handleRemove}
        handleChange={handleChange}
      />
    </div>
  )

}

const mapStateToProps = state => ({
  order: state.orders.order,
})

const mapDispatchToProps = dispatch => ({
  updateOrder: order => dispatch(updateOrder(order)),
})

const connectedProducts = connect(mapStateToProps, mapDispatchToProps)(Products)

export default connectedProducts