import request from 'common/request'

export const adsService = {
  fetchAd,
}

function fetchAd (id) {
  return request.get({
    url: `/v2/adList/${id}`,
  })
}