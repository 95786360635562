import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { fetchBrands } from 'store/actions';
import {
  Typography,
  Link,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px dashed #dedede',
    '&:first-child': {
      paddingTop: 0
    }
  },
  key: {
    color: '#999',
    marginRight: theme.spacing(2)
  },
  values: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(2),
    },
    '& a': {
      color: '#333',
    },
    '& a:hover': {
      color: theme.palette.primary.main,
    }
  }
}));


const ProductsFilters = props => {

  const { className, brands, categories, current = {} } = props;
  const classes = useStyles();
  const { brand = '', category = '' } = current

  const getQuery = (prop, join='') => {
    if (prop === 'brand') {
      return current.category ? `${join}category=${current.category}` : ''
    } else if (prop === 'category') {
      return current.brand ? `${join}brand=${current.brand}` : ''
    }
    return ''
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Typography className={classes.key} variant="subtitle2">BRAND:</Typography>
        <div className={classes.values}>
          <Typography color="primary">
            <Link
              component={RouterLink}
              color="inherit"
              to={`/products?${getQuery('brand')}`}
            >ALL</Link>
          </Typography>
          {
            brands.map(ibrand => {
              if (ibrand.value.toLowerCase() === brand.toLowerCase()) return (
                <Typography color="primary" key={brand}>
                  {brand.toUpperCase()}
                </Typography>
              )
              return (
                <Typography color="textPrimary" key={ibrand.value}>
                  <Link
                    component={RouterLink}
                    to={`/products?brand=${ibrand.value}${getQuery('brand', '&')}`}
                    color="inherit"
                  >{ibrand.value}</Link>
                </Typography>
              )
            })
          }
        </div>
      </div>
      <div className={classes.row}>
        <Typography className={classes.key} variant="subtitle2">CATEGORIES:</Typography>
        <div className={classes.values}>
          <Typography color="textPrimary">
            <Link
              component={RouterLink}
              color="inherit"
              to={`/products?${getQuery('category')}`}
            >ALL</Link>
          </Typography>
          {
            categories.map(cate => {
              if (cate.value === category) return <Typography color="primary" key={category}>{category}</Typography>
              return (
                <Typography color="textPrimary" key={cate.value}>
                  <Link
                    component={RouterLink}
                    color="inherit"
                    to={`/products?category=${cate.value}${getQuery('category', '&')}`}
                  >{cate.value}</Link>
                </Typography>
              )
            })
          }
        </div>
      </div>
      {/* <div className={classes.row}>
        <div className={classes.values}>
          <IconButton
            edge="start"
            color="inherit"
            className={classes.menuButton}
            onClick={ev => changeViewMode('view')}
          >
            <ViewModuleIcon />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            className={classes.menuButton}
            onClick={ev => changeViewMode('table')}
          >
            <GridOnIcon />
          </IconButton>
        </div>
      </div> */}
    </div>
  );
};

const mapStateToProps = state => ({
  brands: state.brands.brands,
  categories: state.brands.categories,
})

const mapDispatchToProps = dispatch => ({
  fetchBrands: () => dispatch(fetchBrands()),
})

const connectedProductsFilters = connect(mapStateToProps, mapDispatchToProps)(ProductsFilters)

export default connectedProductsFilters;
