import { alertConst } from '../constants';

const fns = {
  success,
  warn,
  info,
  error,
  close
}

export function alert ({type, message}) {

  return dispatch => dispatch(fns[type](message))

}

function close () {
  return {
    type: alertConst.CLOSE
  }
}

function success (message) {
  return {
    type: alertConst.SUCCESS,
    message
  }
}

function warn (message) {
  return {
    type: alertConst.WARN,
    message
  }
}

function info (message) {
  return {
    type: alertConst.INFO,
    message
  }
}

function error (message) {
  return {
    type: alertConst.ERROR,
    message
  }
}