import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { connect } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  link: {
    display: 'flex',
    padding: '10px 24px',
    color: '#262e31',
    backgroundColor: 'transparent',
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    transition: 'all 0.35s ease',
    '&:first-child': {
      paddingLeft: 0
    },
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    }
  },
  active: {
    color: theme.palette.primary.main
  }
}));

const RLink = forwardRef((props, ref) => <RouterLink ref={ref} {...props} />);

const Nav = props => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link
        activeClassName={classes.active}
        className={classes.link}
        component={RLink}
        to="/home"
      >
        Home
      </Link>
      <Link
        activeClassName={classes.active}
        className={classes.link}
        component={RLink}
        to="/products?tags=2"
      >
        New Arrivals
      </Link>
      <Link
        activeClassName={classes.active}
        className={classes.link}
        component={RLink}
        to="/quotation"
      >
        Wholesale
      </Link>
      <Link
        activeClassName={classes.active}
        className={classes.link}
        component={RLink}
        to="/about-us"
      >
        About us
      </Link>
      <Link
        activeClassName={classes.active}
        className={classes.link}
        component={RLink}
        to="/contact"
      >
        Contact
      </Link>
    </div>
  );
};

Nav.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = state => ({
  
})

const mapDispatchToProps = dispatch => ({
  
})

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
