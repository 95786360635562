import request from '../../common/request'

export const userService = {
  login,
  logout,
  checkAuth,
  fetchUser,
  fetchUserList,
  patchUser,
  register,
  sendEmail,
  sendResetEmail,
  verifyLink,
  verifyResetToken,
  resetPassword,
  sendMessage,
  fetchAddressList,
  createAddress
}

function login (payload) {
  return request.post({
    url: '/v2/login',
    body: payload
  })
}

function logout () {
  return request.put({
    url: '/v2/logout',
  })
}

function register (payload) {
  return request.post({
    url: '/v2/users',
    body: payload
  })
}

function sendEmail (body) {
  return request.post({
    url: '/v2/sendemail',
    body
  })
}

function sendResetEmail (body) {
  return request.post({
    url: '/v2/sendResetEmail',
    body
  })
}

function resetPassword (body) {
  return request.put({
    url: '/v2/resetPassword',
    body
  })
}

function checkAuth () {
  return request.get({
    url: '/v2/authvalidate'
  })
}

// 验证注册token
function verifyLink (params) {
  return request.get({
    url: `/v2/registerinfo`,
    params
  })
}

// 验证重置密码token
function verifyResetToken (params) {
  return request.get({
    url: `/v2/verifyResetToken`,
    params
  })
}

function fetchUser () {
  return request.get({
    url: `/v2/user`
  })
}

function fetchUserList (options = {}) {
  return request.get({
    url: '/v2/cms/userList',
    params: options
  })
}

function patchUser (user) {
  return request.put({
    url: `/v2/userinfo`,
    body: user
  })
}

function sendMessage (body) {
  return request.post({
    url: '/v2/message',
    body
  })
}

function fetchAddressList () {
  return request.get({
    url: '/v2/addressList',
  })
}

function createAddress (body) {
  return request.post({
    url: '/v2/address',
    body
  })
}