/* eslint-disable no-mixed-operators */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import { ProductItem } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const placeholder = [...Array(16).keys()].map(key => ({id: key}))

const Products = props => {

  const classes = useStyles();
  const { loading, products, currency } = props

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {
          (loading ? placeholder : products).map(product =><ProductItem product={product} currencyMark={currency.code} key={product.id} cols={4}/>)
        }
      </Grid>
    </div>
  );
};


const mapStateToProps = state => ({
  products: state.products.products,
  currency: state.global.currency
})

const connectedProducts = connect(mapStateToProps)(Products)

export default connectedProducts
