import * as React from 'react'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx'
import CloseIcon from '@material-ui/icons/Close'
import {
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  Slide,
} from '@material-ui/core';

import styles from '../../styles';

const useStyles = makeStyles(theme => ({
  ...styles,
  appBar: {
    background: '#fafafa',
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  img: {
    marginRight: 10
  },
  bold: {
    fontWeight: 'bolder'
  },
  product: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  fixedWidth: {
    width: '10%',
    textAlign: 'right'
  },
  paddingRight: {
    paddingRight: 18
  }
}));

const makeAppBarStyles = makeStyles({
  root: {
    position: 'relative',
    color: '#fff',
  },
})

const makeDialogPaperStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
  },
})

const makeListStyles = makeStyles({
  root: {
    flex: 1,
    overflow: 'auto',
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ItemsView (props) {

  const classes = useStyles()
  const { open, items } = props
  const { handleChange, handleRemove, handleClose } = props
  const appStyles = makeAppBarStyles()
  const paperStyle = makeDialogPaperStyles()
  const listStyle = makeListStyles()

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} classes={paperStyle}>
      <AppBar classes={appStyles}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.title}>
            Order Items
          </Typography>
          <IconButton edge="start" color="inherit" onClick={event => handleClose(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ListItem button className={classes.paddingRight}>
        <ListItemText primary="Product" className={classes.main}/>
        <Typography className={clsx(classes.spacing, classes.price)}>Unit Price</Typography>
        <Typography className={clsx(classes.spacing, classes.qty)}>Qty(pcs)</Typography>
        <Typography className={clsx(classes.spacing, classes.total)}>Subtotal</Typography>
        <Typography>Remove</Typography>
      </ListItem>
      <Divider/>
      <List classes={listStyle}>
        {
          items.map((item, index) => (
            <React.Fragment key={item.id}>
              <ListItem component="li">
                <div className={classes.main}>
                  <img src={`${item.img}?imageView2/1/w/60/h/60/ignore-error/1`} alt="" className={classes.img} />
                  <ListItemText secondary={item.spec} primary={item.name} />
                </div>
                <Typography variant="subtitle2" className={clsx(classes.spacing, classes.price)}>${item.salesPrice}</Typography>
                <Typography variant="subtitle2" className={clsx(classes.spacing, classes.qty)}>
                  <TextField
                    className={classes.input}
                    label="Qty"
                    margin="dense"
                    name="qty"
                    type="number"
                    onChange={ev => handleChange(index, item, ev.target.value)}
                    required
                    value={item.qty}
                    variant="outlined"
                    inputProps= {{
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                </Typography>
                <Typography variant="subtitle2" className={clsx(classes.spacing, classes.total)}>${(item.qty * item.salesPrice).toFixed(2)}</Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={ev => handleRemove(index)}
                >
                  <CloseIcon />
                </IconButton>
              </ListItem>
              <Divider component="li"/>
            </React.Fragment>
          ))
        }
      </List>
    </Dialog>
  )
}
