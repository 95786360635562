export default {
  main: {
    width: '47%',
    display: 'flex',
    alignItems: 'center'
  },
  price: {
    width: '10%'
  },
  qty: {
    width: '17%'
  },
  total: {
    width: '13%'
  },
  action: {},
}