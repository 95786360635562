import request from '../../common/request'

export const quotaService = {
  fetchQuota,
  patchQuota
}

function fetchQuota (options) {
  return request.get({
    url: `/v2/priceList`,
    params: options
  })
}

function patchQuota (userId, items) {
  return request.put({
    url: `/v2/cms/user/${userId}/quotation`,
    body: items
  })
}