import request from '../../common/request'

export const productsService = {
  fetchProducts,
  fetchProduct,
  upload,
  fetchProductsBySkuIds,
}

function fetchProducts (options) {
  return request.get({
    url: '/v2/products',
    params: options
  })
}

function fetchProduct (id) {
  return request.get({
    url: `/v2/products/${id}`,
  })
}

function upload (body) {
  return request.post({
    url: '/v2/upload',
    headers: new Headers(),
    multiple: true,
    body: body
  })
}

function fetchProductsBySkuIds (skuIds) {

  return request.post({
    url: '/v2/productsBySkuIds',
    body: skuIds
  })
}