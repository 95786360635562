import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx'
import {
  Typography,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  title: {},
  propsWrap: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  props: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  prop: {
    marginRight: theme.spacing(1),
  },
  value: {
    color: '#333',
    fontWeight: 500
  },
  available: {
    color: theme.palette.primary.main
  }
}));

const Info = (props) => {

  const classes = useStyles();
  const { product } = props
  
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>{product.modelName}</Typography>
      <div className={classes.propsWrap}>
        <div className={classes.props}>
          <Typography variant="subtitle2" className={classes.prop}>Availability:</Typography>
          {
            product.outOfProduction
              ? <Typography variant="subtitle2" className={classes.value}>Out Of Production</Typography>
              : <Typography variant="subtitle2" className={clsx(classes.value, classes.available)}>In Stock</Typography>
          }
        </div>
        <div className={classes.props}>
          <Typography variant="subtitle2" className={classes.prop}>Brand:</Typography>
          <Typography variant="subtitle2" className={classes.value}>{product.brand}</Typography>
        </div>
        <div className={classes.props}>
          <Typography variant="subtitle2" className={classes.prop}>Size:</Typography>
          <Typography variant="subtitle2" className={classes.value}>{product.size}cm</Typography>
        </div>
        <div className={classes.props}>
          <Typography variant="subtitle2" className={classes.prop}>Weight:</Typography>
          <Typography variant="subtitle2" className={classes.value}>{product.weight}g</Typography>
        </div>
      </div>
    </div>
  );
};

export default Info
