import React from 'react';
import XLSX from 'xlsx';
import { connect } from 'react-redux';

import {
  Link,
} from '@material-ui/core';
import { alert } from 'store/actions';

const dataSheet = [
  {
    skuId: 'GAN356RS',
    modelName: 'GAN356RS 3x3 magic cube',
    color: 'stickerless',
    qty: 10
  },
  {
    skuId: 'QY169S',
    modelName: 'QY Warrior W 3x3 magic cube',
    color: 'stickerless',
    qty: 10
  },
  {
    skuId: 'GAN356XSB',
    modelName: 'GAN356XS 3x3 magic cube',
    color: 'black',
    qty: 20
  }
]

function getData (_data) {

  let cols = ['skuId', 'modelName', 'color', 'qty',]

  let data = _data.map(item => [
    item.skuId,
    item.modelName,
    item.color,
    item.qty,
  ])

  return [cols, ...data]
}

function Download (props) {

  const { className, title, Root } = props

  const handleClick = ev => {

    try {
      const fileName = 'order-template-sheet.xlsx'
      const data = getData(dataSheet)
      const wsName = 'SheetJS'
    
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.aoa_to_sheet(data)

      console.log('start writing to wb')
  
      XLSX.utils.book_append_sheet(wb, ws, wsName)
      console.log('start writing file')
      XLSX.writeFile(wb, fileName)
    } catch (err) {
      console.error(err)
      alert('Oops~')
    }
  }

  return (
    <Link
      className={className}
      onClick={handleClick}
      color="textPrimary"
    >
      {title}
    </Link>
  )
}

export default Download