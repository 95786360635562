import { userConst } from '../constants';
import { userService } from '../services';
import browserHistory from 'common/history'
import { alert } from './'

/**
 * an Action is payload of information that send data from your application to your store
 * it is the only source of information for the store
 * we send them to the store using store.dispatch()
 */


/**
 * action creators are functions that create actions
 */

export function auth () {

  return (dispatch, getState) => {

    dispatch(startRequest())
    // dispatch(alert({ type: 'info', message: 'Loading data, please wait...' }))

    userService.checkAuth()
      .then(res => {
        if (res.status === 0 && res.user) {
          let _uesr = Object.assign(res.user, res.user.userInfo)
          dispatch(authSuccess(_uesr))
        } else {
          dispatch(loginFailure())
        }
      })
    
      function startRequest () {
        return {
          type: userConst.AUTH_REQUEST
        }
      }
  }
}

export function login (form, fromRoute = '/') {

  return dispatch => {

    dispatch(startRequest())
    dispatch(alert({type: 'info', message: 'Sending data, please wait..'}))

    userService.login(form)
      .then(res => {
        if (res.status === 0 && res.data) {
          dispatch(alert({type: 'success', message: 'Login success..'}))
          dispatch(authSuccess(res.data))
          browserHistory.push(fromRoute)
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: err.message}))
        dispatch(authFailure())
      })
  }

  function startRequest () {
    return {
      type: userConst.LOGIN_REQUEST
    }
  }
}

export function logOut () {

  return dispatch => {

    userService.logout()
      .then(res => {
        if (res.status === 0) {
          dispatch(signOut())
          browserHistory.replace('/')
        }
      })
  }

  function signOut () {

    return {
      type: userConst.LOGOUT
    }
  }
}

// 注册
export function register (form) {

  return dispatch => {

    dispatch(startRequest())
    dispatch(alert({type: 'info', message: 'Sending data, please wait..'}))

    userService.register(form)
      .then(res => {
        if (res.status === 0 && res.data) {
          dispatch(success(res.data))
          dispatch(alert({type: 'success', message: 'Registeration success..'}))
          browserHistory.replace('/')
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: err.message}))
      })
  }

  function startRequest () {
    return {
      type: userConst.REGISTER_REQUEST
    }
  }

  function success (user) {
    return {
      type: userConst.REGISTER_SUCCESS,
      user
    }
  }

}

// 发送重置邮件
export function sendResetEmail (form) {

  return dispatch => {

    dispatch(startRequest())
    dispatch(alert({type: 'info', message: 'Sending data, please wait..'}))

    userService.sendResetEmail(form)
      .then(res => {
        if (res.status === 0 && res.data) {
          dispatch(sentSuccess())
          dispatch(alert({type: 'success', message: 'An email has been sent to your mail box...'}))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: err.message}))
        dispatch(authFailure())
      })
  }

  function startRequest () {
    return {
      type: userConst.LOGIN_REQUEST
    }
  }

  function sentSuccess () {
    return {
      type: userConst.SEND_EMAIL
    }
  }
}

// 发送验证邮件
export function sendEmail (form) {

  return dispatch => {

    dispatch(startRequest())
    dispatch(alert({type: 'info', message: 'Sending data, please wait..'}))

    userService.sendEmail(form)
      .then(res => {
        if (res.status === 0 && res.data) {
          dispatch(sentSuccess())
          dispatch(alert({type: 'success', message: 'An email has been sent to your mail box...'}))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: err.message}))
        dispatch(authFailure())
      })
  }

  function startRequest () {
    return {
      type: userConst.LOGIN_REQUEST
    }
  }

  function sentSuccess () {
    return {
      type: userConst.SEND_EMAIL
    }
  }
}

export function refresh () {
  return {
    type: userConst.REFRESH_SEND_EMAIL
  }
}

// 验证注册token
export function verifyLink (params) {

  return dispatch => {

    dispatch(verifyRequest())
    dispatch(alert({type: 'info', message: 'Loading data, please wait..'}))
    
    userService.verifyLink(params)
      .then(res => {
        if (res.status === 0) {
          dispatch(verify(true))
        } else {
          return Promise.reject(new Error('This link is no longer valid...'))
        }
      })
      .catch(err => {
        console.error(err.message)
        dispatch(verify(false))
        dispatch(alert({type: 'error', message: 'This link is no longer valid...'}))
      })
  }

  function verifyRequest () {

    return {
      type: userConst.VERIFY_EMAIL_REQUEST
    }
  }

  function verify (isValid) {

    return {
      type: userConst.VERIFY_EMAIL,
      validLink: isValid
    }
  }
}

// 验证重置密码token
export function verifyResetToken (params) {

  return dispatch => {

    dispatch(verifyRequest())
    dispatch(alert({type: 'info', message: 'Loading data, please wait..'}))
    
    userService.verifyResetToken(params)
      .then(res => {
        if (res.status === 0) {
          dispatch(verify(res.data))
        } else {
          return Promise.reject(new Error('This link is no longer valid...'))
        }
      })
      .catch(err => {
        console.error(err.message)
        dispatch(verify(false))
        dispatch(alert({type: 'error', message: 'This link is no longer valid...'}))
      })

    function verifyRequest () {

      return {
        type: userConst.VERIFY_EMAIL_REQUEST
      }
    }
  
    function verify (data) {
  
      return {
        type: userConst.VERIFY_EMAIL,
        ...data
      }
    }
  }
}

// 获取用户信息
export function fetchUser () {

  return (dispatch, getState) => {

    const { auth, user } = getState()

    if (auth.loggingIn || user.loadingUser) return

    dispatch(getRequest())

    userService.fetchUser()
      .then(res => {
        if (res.status === 0) {
          dispatch(getSuccess(res.data))
        } else {
          return Promise.reject(res.message)
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: err.message}))
        dispatch(getFailure(err.message))
      })
  }

  function getRequest () {
    return {
      type: userConst.USER_REQUEST
    }
  }

  function getSuccess (user) {
    return {
      type: userConst.USER_SUCCESS,
      user
    }
  }

  function getFailure (error) {
    return {
      type: userConst.USER_FAILURE,
      error
    }
  }
}

export function getAddressList () {

  return (dispatch, getState) => {

    const { auth, user } = getState()
    if (auth.loggingIn || user.loadingAddressList) return

    dispatch(getRequest())

    userService.fetchAddressList()
      .then(res => {
        if (res.status === 0) {
          dispatch(getSuccess(res.data))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(getFailure(err.message))
      })

  }

  function getRequest () {
    return {
      type: userConst.GET_ADDRESS_LIST_REQUEST
    }
  }

  function getSuccess (addressList) {
    return {
      type: userConst.GET_ADDRESS_LIST,
      addressList
    }
  }

  function getFailure (error) {
    return {
      type: userConst.GET_ADDRESS_LIST_Failure,
      error
    }
  }
}

// 更新用户信息，不保存
export function updateUser (userInfo) {

  return dispatch => {
    dispatch(patchSuccess(userInfo))
  }
}

// 更新用户信息，并保存到服务端
export function patchUser (userInfo) {

  return dispatch => {

    dispatch(patchRequest())

    userService.patchUser({ userInfo })
      .then(res => {
        if (res.status === 0) {
          dispatch(patchSuccess(userInfo))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: err.message}))
        dispatch(patchFailure(err.message))
      })

  }

  function patchRequest () {
    return {
      type: userConst.PATCH_REQUEST
    }
  }

  function patchFailure (error) {
    return {
      error
    }
  }
}

function patchSuccess (user) {
  return {
    type: userConst.PATCH_USER_SUCCESS,
    user
  }
}

function authSuccess (user) {
  return {
    type: userConst.AUTH_SUCCESS,
    user
  }
}

function loginFailure () {
  return {
    type: userConst.LOGIN_FAILURE,
  }
}

function authFailure (user) {
  return {
    user,
    type: userConst.AUTH_FAILURE
  }
}