import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Container } from '..'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  text: {
    marginTop: theme.spacing(2)
  },
}));

export default function Message (props) {

  const classes = useStyles();
  const { order } = props

  return (
    <Container title="Order Note">
      <Typography variant="body2" className={classes.text}>{
        order.customerRemark
      }</Typography>
    </Container>
  );
}
