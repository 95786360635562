import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import Carousel from "react-slick";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  slider: {
    height: '100%',
  },
  arrows: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    zIndex: theme.zIndex.drawer
  },
  prevArrow: {
    left: -30,
  },
  nextArrow: {
    right: -30
  }
}));

const Slier = props => {
  const classes = useStyles();
  const { settings, children, refProps } = props
  const ref = useRef(null)

  const PrevIcon = props => <IconButton {...props} className={clsx(classes.arrows, classes.prevArrow)}><ArrowBackIosIcon/></IconButton>
  const NextIcon = props => <IconButton {...props} className={clsx(classes.arrows, classes.nextArrow)}><ArrowForwardIosIcon/></IconButton>

  const handlePrev = ev => {
    ref.current.slickPrev()
  }
  const handleNext = ev => {
    ref.current.slickNext()
  }

  return (
    <div className={classes.root}>
      {
        settings.arrows ? (
          <>
            <PrevIcon onClick={handlePrev}/>
            <NextIcon onClick={handleNext}/>
          </>
        ): null
      }
      <Carousel {...settings} className={classes.slider} ref={ref}>
        {children}
      </Carousel>
    </div>
  );
};

export default Slier;
