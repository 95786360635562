import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import browserHistory from 'common/history';
import { Link as RouterLink, useParams } from 'react-router-dom';

import {
  Breadcrumbs,
  Typography,
  Paper,
  Divider,
  Button,
  Link,
  Grid,
  Collapse,
  TextField
} from '@material-ui/core';

import { fetchOrder, getAddressList, updateOrder } from 'store/actions'
import { ordersService } from 'store/services'
import { AddressForm, AddressList, Products, Amount, Payment } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    width: theme.content.width,
    margin: '0 auto',
  },
  content: {
    marginTop: theme.spacing(3),
  },
  block: {
    padding: theme.spacing(2),
  },
  spacingTop: {
    marginTop: theme.spacing(3),
  },
}));

function Checkout (props) {

  const classes = useStyles()
  const { order, fetchOrder, updateOrder, getAddressList } = props
  const { orderId } = useParams()
  const [isOrderValid, validateOrder] = useState(false)

  const handleSelectAddress = address => {
    updateOrder(Object.assign({}, order, { address, touchedAddress: true }))
  }

  const handleCreateAddress = address => {
    getAddressList()
    handleSelectAddress(address)
  }

  const handleChange = ev => {
    updateOrder({
      ...order,
      [ev.target.name]: ev.target.value
    })
  }

  const handleSave = ev => {
    if (isOrderValid) {
      validateOrder(false)
      ordersService.patchOrder(order)
        .then(res => {
          if (res.status === 0) {
            browserHistory.replace(`/orders/${orderId}`)
          }
        })
    }
  }

  useEffect(() => {
    fetchOrder(orderId)
  }, [fetchOrder, orderId])

  useEffect(() => {
    let { address, qty, subtotal, items } = order
    let isValid = address && qty > 0 && subtotal > 0 && items.length > 0
    validateOrder(isValid)
  }, [order])

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/" component={RouterLink}>
          Home
        </Link>
        <Link color="inherit">
          {orderId}
        </Link>
      </Breadcrumbs>
      <Grid container spacing={3} className={classes.content}>
        <Grid item xs={6}>
          <Paper className={classes.block}>
            <div className={classes.header}>
              <Typography variant="h4">Shipping Address</Typography>
              <Typography variant="subtitle2">Choose an address or create a new one if you don't have any.</Typography>
            </div>
            <div className={classes.content}>
              <AddressList
                currentId={order.address ? order.address.id: 0}
                handleSelectAddress={handleSelectAddress}
              />
              <Collapse in={!order.address}>
                <AddressForm handleCreate={handleCreateAddress}/>
              </Collapse>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.block}>
            <div className={classes.header}>
              <Typography variant="h4">Your Order</Typography>
              <Typography variant="subtitle2">Order details</Typography>
            </div>
            <div className={classes.content}>
              <Products/>
              <Divider/>
              <Amount items={order.items} order={order} updateOrder={updateOrder}/>
            </div>
          </Paper>
          <Payment
            className={classes.spacingTop}
            method={order.paymentMethod}
            onChange={handleChange}
          />
          <Paper className={clsx(classes.spacingTop, classes.block)}>
            <div className={classes.header}>
              <Typography variant="h4">Order Note</Typography>
              <Typography variant="subtitle2">Leave us a message</Typography>
            </div>
            <div className={classes.content}>
              <TextField
                label="Order Note(optional)"
                multiline
                fullWidth
                rows={2}
                name="customerRemark"
                value={order.customerRemark}
                onChange={handleChange}
                variant="outlined"
                InputLabelProps = {{
                  shrink: true
                }}
              />
            </div>
          </Paper>
          <Button
            variant="contained"
            color="primary"
            className={classes.spacingTop}
            disabled={!isOrderValid}
            onClick={handleSave}
          >Checkout</Button>
        </Grid>
      </Grid>
    </div>
  )

}

const mapStateToProps = state => ({
  order: state.orders.order,
})

const mapDispatchToProps = dispatch => ({
  getAddressList: () => dispatch(getAddressList()),
  fetchOrder: id => dispatch(fetchOrder(id)),
  updateOrder: order => dispatch(updateOrder(order))
})

const connectedCheckout = connect(mapStateToProps, mapDispatchToProps)(Checkout)

export default connectedCheckout