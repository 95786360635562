import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';

import { reject, close } from '../../store/actions'

function Confirm (props) {

  const { message, open, promise } = props
  const { reject, close } = props

  const handleClose = ev => {
    reject()
  }

  const handleConfirm = ev => {
    promise && promise.resolve()
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>操作确认</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          取消
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          确认
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  open: state.confirm.open,
  message: state.confirm.message,
  promise: state.confirm.promise
})

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(close()),
  reject: () => dispatch(reject()),
})

const connectedConfirm = connect(mapStateToProps, mapDispatchToProps)(Confirm)

export default connectedConfirm