import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Paper,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    padding: theme.spacing(2),
  },
  paperContent: {
    paddingTop: 12
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  spacingBottom: {
    marginBottom: 6,
  },
  key: {
    width: 70,
    flex: 'none',
  }
}));

function getAddress (address) {
  return [
    'address',
    'address2',
    'city',
    'state',
    'country'
  ].map(s => address[s]).filter(s => !!s).join(', ')
}

export default function Address (props) {

  const classes = useStyles();
  const { order } = props

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Shipping Address</Typography>
      {
        order.address
        ? (
          <div className={classes.paperContent}>
            <div className={clsx(classes.spacingBottom, classes.flex)}>
              <Typography variant="body2" className={classes.key}>Fullname: </Typography>
              <Typography variant="subtitle2">{`${order.address.firstname || ''} ${order.address.lastname || ''}`}</Typography>
            </div>
            <div className={clsx(classes.spacingBottom, classes.flex)}>
              <Typography variant="body2" className={classes.key}>Mobile: </Typography>
              <Typography variant="subtitle2">{order.address.phone}</Typography>
            </div>
            <div className={clsx(classes.spacingBottom, classes.flex)}>
              <Typography variant="body2" className={classes.key}>Address: </Typography>
              <Typography variant="subtitle2">{getAddress(order.address || [])}</Typography>
            </div>
            <div className={clsx(classes.spacingBottom, classes.flex)}>
              <Typography variant="body2" className={classes.key}>Post Code: </Typography>
              <Typography variant="subtitle2">{order.address.zip}</Typography>
            </div>
          </div>
        ) : null
      }
    </Paper>
  );
}
