import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import browserHistory from 'common/history'
import {
  Typography,
  Tooltip,
  TextField,
  Button
} from '@material-ui/core';

import { saveCart } from 'store/actions'
import { PurchaseList } from '..'

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(3)}px 0`,
  },
  sku: {
    display: 'flex',
    alignItems: 'center',
  },
  price: {
    margin: '0 30px',
    width: 60
  },
  input: {
    width: 96,
    padding: '4px 0 4px 8px',
    border: '1px solid #e5e5e5'
  },
  addButton: {
    marginTop: theme.spacing(3)
  },
  tips: {
    marginLeft: 10,
    color: '#999'
  }
}));

const Skus = (props) => {

  const classes = useStyles();
  const { product, loggedIn, currency, saveCart } = props
  const [items, setItems] = useState([])
  const location = useLocation()
  const total = items.reduce((cur, next) => cur + parseInt(next.qty || 0), 0)

  const handleChange = (id, value) => {

    let index = items.findIndex(item => item.id === id)
    let _items = [...items]
    let target = _items[index]

    if (value < 0 || isNaN(parseInt(value))) {
      _items.splice(index, 1, Object.assign({}, target, { _error: true, qty: value }))
    } else {
      _items.splice(index, 1, Object.assign({}, target, { qty: value, _error: false }))
    }

    setItems(_items)
  }
  
  const handleAddCart = ev => {
    if (!loggedIn) {
      return browserHistory.push({
        pathname: '/sign-in',
        state: {
          from: location.pathname
        }
      })
    }
    saveCart(items)
  }

  useEffect(() => {
    let { items, modelName, cnName, id } = product
    let newItems = items.map(item => Object.assign({}, item, { modelName, cnName, qty: 0, _error: false, productId: id }))
    setItems(newItems)
  }, [product])
  
  return (
    <div className={classes.root}>
      {
        items.map(
          (sku, index) => (
            <div className={classes.sku} key={sku.id}>
              <span className={classes.img}>
                <Tooltip title={sku.spec} arrow placement="top">
                  <img src={`${ sku.img }?imageView2/1/w/60/h/60/ignore-error/1`} alt="" />
                </Tooltip>
              </span>
              <Typography variant="h6" className={classes.price}>{currency.code}{ sku.salesPrice }</Typography>
              <div className={classes.button}>
                <TextField
                  fullWidth
                  label="Qty"
                  helperText={sku._error ? 'Qty must be number and greater than 0' : ''}
                  margin="dense"
                  name="qty"
                  disabled={sku.outOfProduction}
                  onChange={ev => handleChange(sku.id, ev.target.value)}
                  required
                  value={sku.qty}
                  variant="outlined"
                />
              </div>
              {
                sku.outOfProduction && <Typography variant="body2" className={classes.tips}>Out of production</Typography>
              }
            </div>
          )
        )
      }
      <PurchaseList/>
      <Button
        variant="contained"
        color="primary"
        disabled={!total}
        className={classes.addButton}
        onClick={handleAddCart}
      >
        ADD TO CART
      </Button>
    </div>
  );
};

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  product: state.product.product,
  currency: state.global.currency
})

const mapDispatchToProps = dispatch => ({
  saveCart: item => dispatch(saveCart(item)),
})

const connectedDetail = connect(mapStateToProps, mapDispatchToProps)(Skus)

export default connectedDetail
