import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton'
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';

import { getAddressList } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  label: {
    padding: '9px 0'
  }
}));

const useLabelStyles = makeStyles(theme => ({
  root: {
    alignItems: 'flex-start'
  },
}));

const compose = address => {
  return [
    address.address,
    address.city,
    address.state,
    address.country,
    address.zip
  ]
  .filter(s => !!s)
  .join(',')
}

const AddressList = (props) => {

  const classes = useStyles();
  const labelStyles = useLabelStyles()
  let { addressList, currentId = 0, loadingAddressList, getAddressList, handleSelectAddress } = props

  const handleChange = event => {
    const id = +event.target.value
    const address = addressList.find(addr => addr.id === id)
    handleSelectAddress(address)
  };

  const Label = props => {
    const { address } = props
    return (
      <div className={classes.label}>
        <Typography variant="subtitle2">{address.firstname + ' ' + address.lastname}</Typography>
        <Typography variant="subtitle2">{address.phone}</Typography>
        <Typography variant="subtitle2">{compose(address)}</Typography>
      </div>
    )
  }

  useEffect(() => {
    getAddressList()
  }, [getAddressList])

  return (
    <>
    {
      loadingAddressList
        ? [...new Array(3).keys()].map(i => (<Skeleton key={i} animation="wave" height={60} style={{ marginBottom: 8 }}/>))
        : (
          <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1" value={currentId} onChange={handleChange}>
              {
                addressList.map(address => (
                  <FormControlLabel
                    key={address.id}
                    value={address.id}
                    classes={labelStyles}
                    control={<Radio color="primary"/>}
                    label={<Label address={address}/>}
                  />
                ))
              }
              <FormControlLabel
                value={0}
                control={<Radio color="primary"/>}
                label="Use new address"
              />
            </RadioGroup>
          </FormControl>
        )
    }
    </>
  );
};

const mapStateToProps = state => ({
  loadingAddressList: state.user.loadingAddressList,
  addressList: state.user.addressList,
})

const mapDispatchToProps = dispatch => ({
  getAddressList: () => dispatch(getAddressList())
})

const connectedAddressList = connect(mapStateToProps, mapDispatchToProps)(AddressList)

export default connectedAddressList
