import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Breadcrumbs,
  Typography,
  Grid,
} from '@material-ui/core';

import {
  OrderStatus,
  Address,
  Payment,
  Info,
  OrderTable,
  Financial,
  statusClass,
  Message,
  Upload
} from './components'

import { fetchOrder, updateOrder, confirm, patchOrder } from '../../store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    fontSize: 13,
    width: theme.content.width,
    margin: '0 auto'
  },
  content: {
    marginTop: theme.spacing(3),
  },
  inner: {
    width: '100%',
  },
  block: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

function Order (props) {

  const classes = useStyles()
  const { order } = props
  const { fetchOrder, updateOrder } = props
  const { id } = useParams()

  useEffect(() => {
    fetchOrder(id)
  }, [id, fetchOrder])

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/orders">
          Order List
        </Link>
        <Typography color="textPrimary">{order._orderId}</Typography>
      </Breadcrumbs>
      <div className={classes.content}>
        <OrderStatus order={order}/>
        <div className={classes.block}>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={6}>
              <Address order={order}/>
            </Grid>
            <Grid item xs={6}>
              <Payment method={order.paymentMethod}/>
            </Grid>
          </Grid>
        </div>
        <OrderTable order={order}/>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={6}>
            <Info order={order}/>
          </Grid>
          <Grid item xs={6}>
            <Financial/>
          </Grid>
        </Grid>
        <Upload id="bank-slip" order={order} updateOrder={updateOrder}/>
        <Message order={order} updateOrder={updateOrder}/>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loading: state.orders.loading,
  order: state.orders.order,
  patching: state.orders.patching,
  financial: state.orders.financial
})

const mapDispatchToProps = dispatch => ({
  fetchOrder: id => dispatch(fetchOrder(id)),
  updateOrder: payload => dispatch(updateOrder(payload)),
  confirm: options => dispatch(confirm(options)),
  patchOrder: order => dispatch(patchOrder(order))
})

const connectedOrder = connect(mapStateToProps, mapDispatchToProps)(Order)

export default connectedOrder
