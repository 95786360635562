import * as React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
    },
    react: {
      height: 400
    }
  }),
)
export default function Loading (props) {

  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Skeleton animation="wave" height={60} />
        <Skeleton animation="wave" height={60} />
        <Skeleton animation="wave" height={60} />
        <Skeleton animation="wave" variant="rect" className={classes.react} />
      </CardContent>
    </Card>
  )
}
