import { productsConst } from '../constants';

const defaultState = {
  loading: false,
  uploadingPackage: false,
  loaded: false,
  description: '',
  product: {
    id: 0,
    modelName: '',
    cnName: '',
    modelId: '',
    brand: '',
    cate: '',
    size: '',
    weight: '',
    package: '',
    description: '',
    images: [],
    items: [],
    coverImage: {},
    qtyPerCtn: 0
  },
  error: ''
}

export function product (state = defaultState, action) {

  switch (action.type) {
    
    // 获取单个产品
    case productsConst.GET_PRODUCT:
      return Object.assign({}, state, {
        loading: true
      })
    case productsConst.GET_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        product: action.product,
      });
    case productsConst.GET_PRODUCT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    
    // 更新产品
    case productsConst.PATCH_REQUEST:
      return Object.assign({}, state, {
        patching: true
      });
    case productsConst.PATCH_SUCCESS:
      return Object.assign({}, state, {
        patching: false,
        products: action.products
      });
    case productsConst.PATCH_FAILURE:
      return Object.assign({}, state, {
        patching: false,
        error: action.error
      });

    // 更新单个产品-不保存
    case productsConst.MUTATE_PRODUCT:
      return Object.assign({}, state, {
        product: action.product
      });

    // 上传主图
    case productsConst.UPLOADING_PACKAGE:
      return Object.assign({}, state, {
        uploadingPackage: action.uploadingPackage
      })
    case productsConst.UPLOAD_PRIMARY_FAILURE:
      return Object.assign({}, state, {
        error: action.error
      });

    case productsConst.UPDATE_DESCRIPTION:
      return Object.assign({}, state, {
        product: {
          description: action.description
        }
      })

    case productsConst.GET_DEFAULT_PRODUCT:
      return Object.assign({}, state, {
        product: Object.assign({}, defaultState.product, action.product)
      });

    default:
      return state
  }
}