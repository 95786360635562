export function getMarginRate (cost, sales) {
  return ((sales - cost) / cost * 100).toFixed(2) + '%'
}

export function clone (obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function isArray (target) {
  return Object.prototype.toString.call(target) === '[object Array]'
}

export function uuid () {
  let i = 0
  let random: number
  let uuid = ''

  for (i = 0; i < 32; i++) {
    random = Math.random() * 16 | 0
    if (i === 8 || i === 32 || i === 16 || i === 20) {
      uuid += '-'
    }
    uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16)
  }

  return uuid
}