export const userConst = {
  // 注册常量
  REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

  // 登录常量
  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  // 登出常量
  LOGOUT: 'USER_LOGOUT',

  // 鉴权常量
  AUTH_REQUEST: 'USER_AUTH_REQUEST',
  AUTH_SUCCESS: 'USER_AUTH_SUCCESS',
  AUTH_FAILURE: 'USER_AUTH_FAILURE',

  // 单个用户常量
  USER_REQUEST: 'USER_REQUEST',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_FAILURE: 'USER_FAILURE',

  // 发送验证邮件
  SEND_EMAIL: 'SEND_EMAIL',
  REFRESH_SEND_EMAIL: 'REFRESH_SEND_EMAIL',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',

  // 修改用户信息
  PATCH_REQUEST: 'PATCH_USER_REQUEST',
  PATCH_USER_SUCCESS: 'PATCH_USER_SUCCESS',
  PATCH_USER_FAILURE: 'PATCH_USER_FAILURE',

  // 获取地址
  GET_ADDRESS_LIST: 'GET_ADDRESS_LIST',
  GET_ADDRESS_LIST_REQUEST: 'GET_ADDRESS_LIST_REQUEST',
  GET_ADDRESS_LIST_Failure: 'GET_ADDRESS_LIST_Failure',
}