/* eslint-disable no-mixed-operators */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link, Grid, Typography, IconButton } from '@material-ui/core';

import { fetchHomeProducts } from 'store/actions';
import { ProductItem } from './components';
import { Carousel } from '..';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 84
  },
  reverse: {
    flexDirection: 'row-reverse'
  },
  primaryProd: {
    
  },
  prodList: {
    
  },
  carouselBtn: {
    top: -64,
    position: 'absolute',
  },
  nextBtn: {
    right: -16,
  },
  prevBtn: {
    right: 32
  },
  prodBody: {
    position: 'relative',
    padding: '20px 10px',
    border: '1px solid #e5e5e5'
  },
  prodHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleGroup: {
    height: 45,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bolder',
    position: 'relative',
    zIndex: 10,
  },
  subtitle: {
    fontWeight: 800,
    fontSize: 60,
    position: 'absolute',
    left: -3,
    bottom: -16,
    color: '#ccc'
  },
  categories: {
    width: '75%',
    display: 'flex'
  },
  headerLink: {
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  marginRight: {
    margin: `0 ${theme.spacing(1)}px`,
  }
}));

const placeholder = [...Array(8).keys()].map(key => ({id: key}))

const Products = props => {

  const classes = useStyles();
  const { title, data, currency, fetchProducts, options, direction = 'ltr' } = props
  const products = data[options.brand] && data[options.brand].data || placeholder

  const subtitle = title.charAt(0)

  const PrevArrow = props => {
    const { onClick } = props
    return (
      <IconButton className={clsx(classes.prevBtn, classes.carouselBtn)} onClick={onClick}>
        <ArrowLeftIcon/>
      </IconButton>
    )
  }

  const NextArrow = props => {
    const { onClick } = props
    return (
      <IconButton className={clsx(classes.nextBtn, classes.carouselBtn)} onClick={onClick}>
        <ArrowRightIcon/>
      </IconButton>
    )
  }

  const covers = {
    'gan': 'http://images2.diascubes.com/1603201886467-gan.jpg',
    'moyu': 'http://images2.diascubes.com/1603201886469-moyu-cube.jpg',
    'qiyi': 'http://images2.diascubes.com/1603201886555-qiyi-cube.jpg',
    'yongjun': 'http://images2.diascubes.com/1603201886477-YJ.jpg'
  }

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    prevArrow: <PrevArrow/>,
    nextArrow: <NextArrow/>,
  }

  useEffect(() => {
    fetchProducts(options.brand, options)
  }, [options, fetchProducts])

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={clsx({ [classes.reverse]: direction === 'rtl' })}>
        <Grid item className={classes.primaryProd} xs={3}>
          <img src={covers[options.brand]} alt=""/>
        </Grid>
        <Grid item className={classes.prodList} xs={9}>
          <div className={classes.prodHeader}>
            <div className={classes.titleGroup}>
              <Typography variant="h3" className={classes.title}>{title}</Typography>
              <div className={classes.subtitle}>{subtitle}</div>
            </div>
            <div className={classes.categories}>
              <Link
                className={classes.headerLink}
                component={RouterLink}
                to={`/products?brand=${options.brand}&categories=2x2x2`}
              >2x2</Link>
              <Link
                className={classes.headerLink}
                component={RouterLink}
                to={`/products?brand=${options.brand}&categories=3x3x3`}
              >3x3</Link>
              <Link
                className={classes.headerLink}
                component={RouterLink}
                to={`/products?brand=${options.brand}&categories=4x4x4`}
              >4x4</Link>
              <Link
                className={classes.headerLink}
                component={RouterLink}
                to={`/products?brand=${options.brand}&categories=Pyraminx`}
              >Pyraminx</Link>
              <Link
                className={classes.headerLink}
                component={RouterLink}
                to={`/products?brand=${options.brand}&categories=Megaminx`}
              >Megaminx</Link>
              <Link
                className={classes.headerLink}
                component={RouterLink}
                to={`/products?brand=${options.brand}&categories=accessories`}
              >Accessories</Link>
            </div>
          </div>
          <div className={classes.prodBody}>
            <div className={classes.carouselContainer}>
              <Carousel settings={settings}>
                {
                  products.map(prod => <ProductItem product={prod} key={prod.id} className={classes.spacing}/>)
                }
              </Carousel>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};


const mapStateToProps = state => ({
  data: state.products.home,
  currency: state.global.currency
})

const mapDispatchToProps = dispatch => ({
  fetchProducts: (key, options) => dispatch(fetchHomeProducts(key, options)),
})

const connectedProducts = connect(mapStateToProps, mapDispatchToProps)(Products)

export default connectedProducts
