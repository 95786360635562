import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';

import {
  Banner,
  Services,
  NewArrivals,
  Products,
  BrandList,
  Recommend,
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: 15,
  },
  services: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: `${theme.spacing(3)}px 0`
  },
  serviceItem: {
    width: '32.2%',
    paddingRight: theme.spacing(2),
    boxSizing: 'border-box',
    border: '1px solid #e5e5e5',
    padding: 20
  },
  serviceBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  serviceContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1)
  },
  serviceSubtitle: {
    fontSize: 13,
    color: '#999'
  }
}));

const Dashboard = props => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Banner/>
        <Services/>
        <NewArrivals/>
        <Products title="GAN Cubes" options={{ brand: 'gan', pageSize: 12 }}/>
        <Products title="MoYu Cubes" direction="rtl" options={{ brand: 'moyu', pageSize: 12 }}/>
        <Products title="Qiyi Cubes" options={{ brand: 'qiyi', pageSize: 12 }}/>
        <Products title="YJ Cubes" direction="rtl" options={{ brand: 'yongjun', pageSize: 12 }}/>
        <Recommend title="Recommended Products" options={{ tags: 3, pageSize: 8 }}/>
        <BrandList/>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
})

export default connect(mapStateToProps)(Dashboard);
