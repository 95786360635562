export const cubeBrands = [
  {
    label: 'GAN',
    value: 'GAN'
  },
  {
    label: 'GAN-MG',
    value: 'GAN-MG'
  },
  {
    label: 'MOYU',
    value: 'MOYU'
  },
  {
    label: 'MeiLong',
    value: 'MOYU-MeiLong'
  },
  {
    label: 'QiYi',
    value: 'QiYi'
  },
  {
    label: 'YJ',
    value: 'Yongjun'
  },
  {
    label: 'Sengso',
    value: 'Shengshou'
  },
  {
    label: 'Yuxin',
    value: 'Yuxin'
  },
  {
    label: 'Diansheng',
    value: 'Diansheng'
  },
  {
    label: 'Dayan',
    value: 'Dayan'
  },
  {
    label: 'SpeedStacks',
    value: 'SpeedStacks'
  },
  {
    label: 'Fanxin',
    value: 'Fanxin'
  },
  {
    label: 'MF8',
    value: 'MF8'
  },
  {
    label: 'CycloneBoys',
    value: 'CycloneBoys'
  },
  {
    label: 'Lingao',
    value: 'Lingao'
  },
  {
    label: 'Lanlan',
    value: 'Lanlan'
  },
  {
    label: 'VeryPuzzle',
    value: 'VeryPuzzle'
  },
  {
    label: 'Kungfu',
    value: 'Kungfu'
  },
  {
    label: 'Fangshi',
    value: 'Fangshi'
  },
  {
    label: 'Zcube',
    value: 'Zcube'
  },
  {
    label: 'Lefun',
    value: 'Lefun'
  },
  {
    label: 'WitEden',
    value: 'WitEden'
  },
  {
    label: 'XHMOBER',
    value: 'XHMOBER'
  },
  {
    label: 'Giiker',
    value: 'Giiker'
  },
  {
    label: 'CubeTwist',
    value: 'CubeTwist'
  },
  {
    label: 'HeShu',
    value: 'HeShu'
  },
  {
    label: 'MAD HEDZ',
    value: 'MAD HEDZ'
  },
  {
    label: 'MARU',
    value: 'MARU'
  },
  {
    label: 'Neo Cube',
    value: 'Neo Cube'
  },
  {
    label: 'QJ',
    value: 'QJ'
  },
  {
    label: 'Other brand',
    value: 'Others'
  },
]

export const cubeCategories = [
  {
    label: '1x3x3',
    value: '1x3x3',
  },
  {
    label: '1x2x3',
    value: '1x2x3',
  },
  {
    label: '2x2x3',
    value: '2x2x3',
  },
  {
    label: '2x2x2',
    value: '2x2x2',
  },
  {
    label: '3x3x3',
    value: '3x3x3',
  },
  {
    label: '4x4x4',
    value: '4x4x4',
  },
  {
    label: '5x5x5',
    value: '5x5x5',
  },
  {
    label: '6x6x6',
    value: '6x6x6',
  },
  {
    label: '7x7x7',
    value: '7x7x7',
  },
  {
    label: '8x8x8',
    value: '8x8x8',
  },
  {
    label: '9x9x9',
    value: '9x9x9',
  },
  {
    label: 'Mastermorphix',
    value: 'Mastermorphix',
  },
  {
    label: 'Megaminx',
    value: 'Megaminx',
  },
  {
    label: 'Mirror',
    value: 'Mirror',
  },
  {
    label: 'Pyraminx',
    value: 'Pyraminx',
  },
  {
    label: 'SQ-1',
    value: 'SQ-1',
  },
  {
    label: 'Skewb',
    value: 'Skewb',
  },
  {
    label: '10x10x10',
    value: '10x10x10',
  },
  {
    label: '11x11x11',
    value: '11x11x11',
  },
  {
    label: '12x12x12',
    value: '12x12x12',
  },
  {
    label: '13x13x13',
    value: '13x13x13',
  },
  {
    label: '15x15x15',
    value: '15x15x15',
  },
  {
    label: 'Irregular Cube',
    value: 'Irregular Cube',
  },
  {
    label: '17x17x17',
    value: '17x17x17',
  },
  {
    label: 'Gift Package',
    value: 'Gift Package',
  },
  {
    label: 'Accessories',
    value: 'Accessories',
  },
  {
    label: 'Keychain',
    value: 'Keychain',
  },
  {
    label: 'Other toys',
    value: 'Other toys',
  },
]