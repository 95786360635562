import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    backgroundColor: '#f8f8f8',
    marginLeft: 'calc(100% - 100vw)'
  },
  container: {
    width: theme.content.width,
    margin: '0 auto',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
  flex: {
    display: 'flex',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  inner: {
    paddingBottom: 30
  },
  links: {
    paddingTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #e5e5e5'
  },
  bar: {
    width: 0,
    height: 12,
    display: 'inline-block',
    borderLeft: '1px solid #aaa',
    margin: '0 10px',
  },
  logo: {
    width: 180,
    marginLeft: -6,
    marginBottom: theme.spacing(1),
    '& img': {
      width: '100%'
    }
  },
  subtitle: {
    height: 56,
    display: 'flex',
    color: '#333',
    alignItems: 'center',
    fontSize: 14,
    padding: '10px 0',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  cols: {
    padding: '0 15px',
    maxWidth: 360
  },
  placeholder: {
    height: 52.6,
    marginBottom: 10
  },
  brandsWrap: {
    width: '18%'
  },
  brands: {
    flexWrap: 'wrap',
  },
  brand: {
    minWidth: '40%'
  },
  row: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'flex-start',
    lineHeight: 1.6,
    marginBottom: theme.spacing(1),
  },
  key: {
    color: '#333',
    minWidth: 70,
    '& a': {
      color: 'inherit',
      fontSize: 'inherit',
    }
  },
  val: {
    color: '#666'
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const categories = [{
    name: 'New Arrivals',
    search: 'tags',
    value: 2
  },{
    name: 'Hot Sales',
    search: 'tags',
    value: 1
  },{
    name: 'Recommended Products',
    search: 'tags',
    value: 3
  },{
    name: 'Cube Accessories',
    search: 'category',
    value: 'Accessories'
  },{
    name: 'Other Toys',
    search: 'category',
    value: 'other toys'
  }]

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.container}>
        <div className={clsx(classes.spaceBetween, classes.flex, classes.inner)}>
          <div className={classes.cols}>
            <div className={classes.logo}>
              <img src="http://images2.diascubes.com/1604238676340-diascubes.png" alt=""/>
            </div>
            <div className={classes.row}>
              <div className={classes.key}>Company:</div>
              <div className={classes.val}>YIWU MOYU E-COMMERCE CO., LTD</div>
            </div>
            <div className={classes.row}>
              <div className={classes.key}>ADD:</div>
              <div className={classes.val}>Jiangdong District, Yiwu, Zhejiang, China</div>
            </div>
            <div className={classes.row}>
              <div className={classes.key}>PHONE:</div>
              <div className={classes.val}>(+86) 16621619269</div>
            </div>
            <div className={classes.row}>
              <div className={classes.key}>EMAIL:</div>
              <div className={classes.val}>wwh@diascubes.com</div>
            </div>
          </div>
          <div className={classes.cols}>
            <div className={classes.placeholder}></div>
            <div className={classes.row}>
              <div className={classes.key}>Company:</div>
              <div className={classes.val}>Huang Weiwei,Dooruo International Trading (Shanghai) Co., Ltd</div>
            </div>
            <div className={classes.row}>
              <div className={classes.key}>ADD:</div>
              <div className={classes.val}>No.95 Jianghainan Road,Fengxian Dis,Shanghai,China</div>
            </div>
            <div className={classes.row}>
              <div className={classes.key}>Business license: </div>
              <div className={classes.val}>91310120342227862P</div>
            </div>
            <div className={classes.row}>
              <div className={classes.key}>PHONE:</div>
              <div className={classes.val}>(+86) 15618227807</div>
            </div>
            <div className={classes.row}>
              <div className={classes.key}>EMAIL:</div>
              <div className={classes.val}>info@diascubes.com</div>
            </div>
          </div>
          <div className={clsx(classes.cols, classes.brandsWrap)}>
            <div className={classes.subtitle}>
              <Typography variant="h5">CUBE BRANDS</Typography>
            </div>
            <div className={clsx(classes.flex, classes.brands)}>
              {
                [
                  'GAN', 'MOYU', 'QiYi', 'YongJun', 'Sengso', 'Diansheng',
                  'Dayan', 'Cycloneboys',' Lanlan', 'VeryPuzzle', 'Kungfu',
                  'Fangshi', 'MF8', 'Zcube',
                ].map(
                  brand => (
                    <div className={clsx(classes.row, classes.brand)} key={brand}>
                      <div className={classes.key}>
                        <Link
                          color="inherit"
                          to={`/products?brand=${brand}`}
                          variant="body2"
                        >
                          {brand}
                        </Link>
                      </div>
                    </div>
                  )
                )
              }
            </div>
          </div>
          {/* <div className={classes.cols}>
            <div className={classes.subtitle}>
              <Typography variant="h5">HOT CATEGORIES</Typography>
            </div>
            <div>
              {
                categories.map(
                  cate => (
                    <div className={classes.row} key={cate.name}>
                      <div className={classes.key}>
                        <Link
                          color="inherit"
                          to={`/products?${cate.search}=${cate.value}`}
                          variant="body2"
                        >
                          {cate.name}
                        </Link>
                      </div>
                    </div>
                  )
                )
              }
            </div>
          </div> */}
          <div className={classes.cols}>
            <div className={classes.subtitle}>
              <Typography variant="h5">CUSTOMER SERVICE</Typography>
            </div>
            <div>
              <div className={classes.row}>
                <div className={classes.key}>
                  <Link
                    color="inherit"
                    to="/profile"
                    variant="body2"
                  >
                    Profile
                  </Link>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.key}>
                  <Link
                    color="inherit"
                    to="/orders"
                    variant="body2"
                  >
                    Orders
                  </Link>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.key}>
                  <Link
                    color="inherit"
                    to="/quotation"
                    variant="body2"
                  >
                    Price List
                  </Link>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.key}>
                  <Link
                    color="inherit"
                    to="/contact"
                    variant="body2"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.key}>
                  <Link
                    color="inherit"
                    to="/about-us"
                    variant="body2"
                  >
                    About Us
                  </Link>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.key}>
                  <Link
                    color="inherit"
                    to="/terms"
                    variant="body2"
                  >
                    Terms and Conditions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className={classes.links}>
          <Typography variant="caption">
            Copyright &copy;{new Date().getFullYear()}
            <a href="https://beian.miit.gov.cn/" target="_blank" color="inherit">沪ICP备17013863号-1</a>
          </Typography>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
