const statusList = [
  {
    label: 'Order created',
    value: 201
  },
  {
    label: 'Awaiting confirmation',
    value: 202
  },
  {
    label: 'Awaiting payment',
    value: 203
  },
  {
    label: 'Awaiting shipment',
    value: 204
  },
  {
    label: 'Order Shipped',
    value: 205
  },
]

const statusClass = {
  WAIT_BUYER_CONFIRM: 201,
  WAIT_SALLER_CONFIRM: 202,
  
  WAIT_PAY: 203,
  WAIT_DELIVERY: 204,
  WAIT_RECEIVE: 205,
  COMPLETE: 206,
  CLOSED: -999,
}

export {statusList, statusClass}