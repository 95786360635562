import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
  Typography,
  Paper
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    padding: theme.spacing(2),
  },
  paperContent: {
    paddingTop: 12
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  spacingBottom: {
    marginBottom: 6,
  },
  key: {
    width: 130,
    flex: 'none',
  }
}));

const PaymentAccount = props => {
  const { method, classes } = props
  switch (method) {
    case 'Paypal':
      return (
        <div className={clsx(classes.spacingBottom, classes.flex)}>
          <Typography variant="body2" className={classes.key}>Account Name:</Typography>
          <Typography variant="subtitle2">walle.wwh@gmail.com</Typography>
        </div>
      )
    case 'Western Union':
      return (
        <div className={clsx(classes.spacingBottom, classes.flex)}>
          <Typography variant="body2" className={classes.key}>Account Name:</Typography>
          <Typography variant="subtitle2">Huang weiwei (first name is weiwei)</Typography>
        </div>
      )
    case 'Bank Transfer(USD)':
      return (
        <>
          <div className={clsx(classes.spacingBottom, classes.flex)}>
            <Typography variant="body2" className={classes.key}>Beneficiary’s Name:</Typography>
            <Typography variant="subtitle2">Huang Weiwei  (Weiwei is the first name)</Typography>
          </div>
          <div className={clsx(classes.spacingBottom, classes.flex)}>
            <Typography variant="body2" className={classes.key}>Beneficiary’s Address:</Typography>
            <Typography variant="subtitle2">Room 201,No.59,Changchun 7 jie,FuTian Dis,YiWu,China</Typography>
          </div>
          <div className={clsx(classes.spacingBottom, classes.flex)}>
            <Typography variant="body2" className={classes.key}>Bank Name:</Typography>
            <Typography variant="subtitle2">China Zheshang Bank YiWu Branch</Typography>
          </div>
          <div className={clsx(classes.spacingBottom, classes.flex)}>
            <Typography variant="body2" className={classes.key}>Bank’s Address:</Typography>
            <Typography variant="subtitle2">No.955,BeiCun Road,YiWu,ZheJiang,China</Typography>
          </div>
          <div className={clsx(classes.spacingBottom, classes.flex)}>
            <Typography variant="body2" className={classes.key}>Account No:</Typography>
            <Typography variant="subtitle2">3387020011410100058911</Typography>
          </div>
          <div className={clsx(classes.spacingBottom, classes.flex)}>
            <Typography variant="body2" className={classes.key}>Swift Code:</Typography>
            <Typography variant="subtitle2">ZJCBCN2N</Typography>
          </div>
        </>
      )
    default:
      return (
        <>
          <div className={clsx(classes.spacingBottom, classes.flex)}>
            <Typography variant="body2" className={classes.key}>Bank Name:</Typography>
            <Typography variant="subtitle2">中国银行</Typography>
          </div>
          <div className={clsx(classes.spacingBottom, classes.flex)}>
            <Typography variant="body2" className={classes.key}>Account Name:</Typography>
            <Typography variant="subtitle2">李海洋</Typography>
          </div>
          <div className={clsx(classes.spacingBottom, classes.flex)}>
            <Typography variant="body2" className={classes.key}>Account No:</Typography>
            <Typography variant="subtitle2">6217850800011695283</Typography>
          </div>
        </>
      )
  }
}

function Payment (props) {

  const classes = useStyles()
  const { method } = props

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Payment Method</Typography>
      <div className={classes.paperContent}>
        <div className={clsx(classes.spacingBottom, classes.flex)}>
          <Typography variant="body2" className={classes.key}>Payment Method:</Typography>
          <Typography variant="subtitle2">{method}</Typography>
        </div>
        <PaymentAccount
          classes={classes}
          method={method}
        />
      </div>
    </Paper>
  )

}

export default Payment