import { brandsConst } from '../constants';
import { brandsService } from '../services';
import { alert } from './'

export function fetchBrands () {

  return (dispatch, getState) => {
    
    const { auth, brands } = getState()

    if (auth.loggingIn || brands.loading) return

    dispatch(getRequest())

    brandsService.fetchBrands()
      .then(res => {
        if (res.status === 0) {
          dispatch(getSuccess(res.data))
        } else {
          return Promise.reject(res.message)
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: err.message}))
        dispatch(getFailure(err.message))
      })
  }

  function getRequest () {
    return {
      type: brandsConst.GET_REQUEST
    }
  }

  function getSuccess (brands) {
    return {
      type: brandsConst.GET_SUCCESS,
      brands
    }
  }

  function getFailure (error) {
    return {
      type: brandsConst.GET_FAILURE,
      error
    }
  }
}

export function getBrands () {

  return dispatch => {

    dispatch(getBrands())
    dispatch(getCategories())
  }

  function getBrands () {
    return {
      type: brandsConst.GET_BRANDS,
    }
  }

  function getCategories () {
    return {
      type: brandsConst.GET_CATEGORIES,
    }
  }
}
