import { alertConst } from '../constants';

export function alert (state = { open: false }, action) {
  switch (action.type) {
    case alertConst.SUCCESS:
      return Object.assign({}, state, {
        open: true,
        alertType: 'success',
        message: action.message
      })
    case alertConst.INFO:
      return Object.assign({}, state, {
        open: true,
        alertType: 'info',
        message: action.message
      })
    case alertConst.WARN:
      return Object.assign({}, state, {
        open: true,
        alertType: 'warning',
        message: action.message
      })
    case alertConst.ERROR:
      return Object.assign({}, state, {
        open: true,
        alertType: 'error',
        message: action.message
      })
    case alertConst.CLOSE:
      return Object.assign({}, state, {
        open: false,
        message: ''
      })
    default:
      return state
  }
}