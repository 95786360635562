import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Button } from '@material-ui/core';

import { SearchInput } from 'components';
import { Select } from 'components';
import { statusList } from '../status';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  searchInput: {
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: 7,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
}));

const Toolbar = props => {
  const { className, handleChange, ...rest } = props;

  const classes = useStyles();

  const [search, setValue] = useState('')
  const [status, setStatus] = useState('')

  const handleInputChange = ev => {
    setValue(ev.target.value)
  }

  const handleSelect = ev => {
    const value = ev.target.value
    setStatus(value)
    handleChange({ name: 'trackStatus', value })
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <Select
          options={statusList}
          name="status"
          defaultValue="All Orders"
          value={status}
          onChange={handleSelect}
        />
        <Paper className={classes.searchInput}>
          <SearchInput
            name="search"
            value={search}
            placeholder="product name，model Id etc."
            onChange={handleInputChange}
          />
        </Paper>
        <Button
          color="primary"
          variant="contained"
          onClick={ev => handleChange({ name: 'search', value: search })}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  search: PropTypes.string,
  handleChange: PropTypes.func
};

export default Toolbar;
