import request from '../../common/request'

export const ordersService = {
  fetchOrders,
  fetchOrder,
  patchOrder,
  createOrder,
  fetchCities
}

function createOrder (options) {
  return request.post({
    url: `/v2/order`,
    body: options
  })
}

function fetchOrders (options) {
  return request.get({
    url: `/v2/orders`,
    params: options
  })
}

function fetchOrder (id) {
  return request.get({
    url: `/v2/order/${id}`,
  })
}

function patchOrder (order) {
  return request.put({
    url: `/v2/order/${order._orderId}`,
    body: order
  })
}

function fetchCities (options) {
  return request.get({
    url: `/v2/address/state`,
    params: options
  })
}