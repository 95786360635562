export default {
  main: {
    width: '57%',
    display: 'flex',
    alignItems: 'center',
  },
  price: {
    width: '8%'
  },
  qty: {
    width: '20%'
  },
  total: {
    width: '13%'
  },
  action: {},
}