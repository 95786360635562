import { quotaConst } from '../constants';

const defaultState = {
  loadingQuota: false,
  patching: false,
  items: [],
  user: {
    marginRate: 0.2,
    exchangeRate: 6.5
  },
  currencyCode: 'USD',
  totalCount: 0,
  error: ''
}

export function quota (state = defaultState, action) {
  switch (action.type) {

    // 获取报价
    case quotaConst.GET_QUOTA_REQUEST:
      return Object.assign({}, state, {
        loadingQuota: true
      });
      case quotaConst.GET_QUOTA_SUCCESS:
        return Object.assign({}, state, {
          loadingQuota: false,
          items: action.items,
          user: action.user,
          currencyCode: action.currencyCode,
          totalCount: action.totalCount
        });
      case quotaConst.GET_QUOTA_FAILURE:
        return Object.assign({}, state, {
          loadingQuota: false,
          error: action.error
        });

    // 修改报价
    case quotaConst.PATCH_REQUEST:
      return Object.assign({}, state, {
        patching: true
      });
    case quotaConst.PATCH_SUCCESS:
      return Object.assign({}, state, {
        patching: false,
        quotas: action.quotas
      });
    case quotaConst.PATCH_FAILURE:
      return Object.assign({}, state, {
        patching: false,
        error: action.error
      });

    // 修改用户利润率和汇率
    case quotaConst.MUTATE_USER:
      return Object.assign({}, state, {
        user: action.user
      })

    default:
      return state
  }
}