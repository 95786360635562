import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Carousel } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 30,
    width: '100%',
    position: 'relative',
    '& a': {
      display: 'block',
      width: '100%',
      height: '100%'
    }
  },
  imgWrap: {
    height: 500,
    padding: 10,
    textAlign: 'center',
    '& img': {
      display: 'inline-block',
    }
  },
  border: {
    height: '100%',
    border: '15px solid #ccc',
  },
  img: {
    height: '100%',
  },
  dots: {
    bottom: 20,
    '& li button::before': {
      transition: 'transform 0.2s linear'
    },
    '& li.slick-active button::before': {
      color: theme.palette.primary.main,
      opacity: 1,
      transform: 'scale(1.5)'
    }
  }
}));


const certs = [
  {
    img: 'http://images2.diascubes.com/1667047420247-YJ.jpg',
  },
  {
    img: 'http://images2.diascubes.com/1667047419768-大雁.jpg',
  },
  {
    img: 'http://images2.diascubes.com/1667047416290- QY.jpg',
  },
  {
    img: 'http://images2.diascubes.com/1667047420390-GAN.jpg',
  },
  {
    img: 'http://images2.diascubes.com/1667192123493-moyu.jpeg',
  },
]

const Cert = props => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    speed: 500,
    dotsClass: clsx(classes.dots, 'slick-dots')
  };

  return (
    <div className={classes.root}>
      <Carousel settings={settings}>
        {
          certs.map(banner => (
            <div key={banner} className={classes.imgWrap}>
              <div className={classes.border}>
                <img src={banner.img} alt="First slide" className={classes.img} />
              </div>
            </div>
          ))
        }
      </Carousel>
    </div>
  );
};

export default Cert;