import {
  Dashboard as DashboardView,
  Products as ProductListView,
  // Icons as IconsView,
  Account as AccountView,
  Quota as QuotaView,
  // Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  SignOut as SignOutView,
  NotFound as NotFoundView,
  Orders as OrdersView,
  Order as OrderView,
  Terms as TermsView,
  Verify as VerifyView,
  About as AboutView,
  Contact as ContactView,
  Detail as DetailView,
  PurchaseList as PurchaseListView,
  Checkout as CheckoutView,
  Reset as resetView,
  VerifyReset as VerifyResetView
} from './views';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

export default props => ([
  {
    path: '/home',
    component: DashboardView,
    exact: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
    }
  },
  {
    path: '/products',
    component: ProductListView,
    exact: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
    }
  },
  {
    path: '/products/:id',
    component: DetailView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/checkout/:orderId',
    component: CheckoutView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/orders',
    component: OrdersView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/orders/:id',
    component: OrderView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/purchase-list',
    component: PurchaseListView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/profile',
    component: AccountView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  // {
  //   path: '/settings',
  //   component: SettingsView,
  //   exact: true,
  //   layout: MainLayout,
  //   props: {
  //     loggingIn: props.loggingIn,
  //     loggedIn: props.loggedIn,
  //     authorized: props.authorized
  //   }
  // },
  {
    path: '/quotation',
    component: QuotaView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized,
    }
  },
  {
    path: '/sign-up',
    component: SignUpView,
    exact: true,
    layout: MinimalLayout,
  },
  {
    path: '/verify-email',
    component: VerifyView,
    exact: true,
    layout: MinimalLayout,
  },
  {
    path: '/sign-in',
    component: SignInView,
    exact: true,
    layout: MinimalLayout,
  },
  {
    path: '/sign-out',
    component: SignOutView,
    exact: true,
    layout: MinimalLayout,
  },
  {
    path: '/send-reset-email',
    component: resetView,
    exact: true,
    layout: MinimalLayout,
  },
  {
    path: '/reset-password',
    component: VerifyResetView,
    exact: true,
    layout: MinimalLayout
  },
  {
    path: '/not-found',
    component: NotFoundView,
    exact: true,
    layout: MinimalLayout,
  },
  {
    path: '/terms',
    component: TermsView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/about-us',
    component: AboutView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/contact',
    component: ContactView,
    exact: true,
    layout: MinimalLayout,
  },
])