import * as React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

export default function Loading (props) {

  return (
    <>
      {
        [...new Array(10).keys()].map(i => (
          <Skeleton key={i} animation="wave" height={60} style={{ marginBottom: 8 }}/>)
        )
      }
    </>
  )
}
