import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Divider
} from '@material-ui/core';

import { statusList, Download } from '..'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
  buttons: {
    paddingTop: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
  }
}));

function getSteps () {
  return statusList.map(status => status.label)
}

function getTime (order) {
  return [
    order.createdAt,
    order.customerSubmitDate,
    order.stockConfirmDate,
    order.paymentDate,
    order.deliveryDate,
    order.receptionDate
  ]
}

export default function OrderStatus (props) {

  const classes = useStyles();
  const steps = getSteps();
  const { order } = props
  const activeStep = statusList.findIndex(each => each.value === order.trackStatus)
  const dates = getTime(order)

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {}
          const date = dates[index]
          if (date) {
            labelProps.optional = <Typography variant="caption">{moment(date).format('YYYY-MM-DD hh:mm:ss')}</Typography>
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                {...labelProps}
              >{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Divider/>
      <Paper square elevation={0} className={classes.paper}>
        <Typography variant="subtitle2">Order Number：<strong>{order._orderId}</strong></Typography>
        <Typography variant="subtitle2">Status：<strong>{steps[activeStep]}</strong></Typography>
        <div className={classes.buttons}>
          <Download
            className={classes.button}
          />
        </div>
      </Paper>
    </div>
  );
}
