import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { RouteWithLayout, ProtectedRouteWithLayout } from './components';

import RoutesMapper from './RoutesMapper'

const Routes = props => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/home"
      />
      {
        RoutesMapper(props).map(route => {
          const WrapperComponent = route.protected ? ProtectedRouteWithLayout : RouteWithLayout
          return (
            <WrapperComponent
              key={route.path}
              component={route.component}
              exact={route.exact}
              path={route.path}
              layout={route.layout}
              {...route.props}
            />
          )
        })
      }
      <Redirect to="/not-found" />
    </Switch>
  );
};

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  authorized: state.auth.authorized,
  loggingIn: state.auth.loggingIn
})

const connectedRoutes = connect(mapStateToProps)(Routes)

export default connectedRoutes;
