import { productsConst } from '../constants';
import { productsService } from '../services';

// 获取首页商品列表
export function fetchHomeProducts (key, options) {

  return (dispatch, getState) => {

    const { products } = getState()
    if (products.home && products.home[key] && products.home[key].loading) return
    
    dispatch(getRequest({key}))

    productsService.fetchProducts (options)
      .then(res => {
        if (res.status === 0) {
          dispatch(getSuccess(key, res.data))
        } else {
          return Promise.reject(res.message)
        }
      })
      .catch(err => {
        dispatch(getFailure(key, err.message))
      })
  }

  function getRequest ({key}) {
    return {
      type: productsConst.GET_HOME_REQUEST,
      key
    }
  }

  function getSuccess (key, {prods, currencyCode, totalCount}) {
    return {
      type: productsConst.GET_HOME_SUCCESS,
      key,
      products: prods,
      currencyCode,
      totalCount
    }
  }

  function getFailure (key, error) {
    return {
      type: productsConst.GET_HOME_FAILURE,
      error
    }
  }
}

// 获取商品列表
export function fetchProducts (options) {

  return (dispatch, getState) => {

    const { products } = getState()
    if (products.loading) return
    
    dispatch(getRequest())

    productsService.fetchProducts (options)
      .then(res => {
        if (res.status === 0) {
          dispatch(getSuccess(res.data))
        } else {
          return Promise.reject(res.message)
        }
      })
      .catch(err => {
        dispatch(getFailure(err.message))
      })
  }

  function getRequest () {
    return {
      type: productsConst.GET_REQUEST,
    }
  }

  function getSuccess ({prods, currencyCode, totalCount}) {
    return {
      type: productsConst.GET_SUCCESS,
      products: prods,
      currencyCode,
      totalCount
    }
  }

  function getFailure (error) {
    return {
      type: productsConst.GET_FAILURE,
    }
  }
}