import { isArray } from 'helpers'
import { cacheConst } from '../constants';

// 存入缓存
export function saveCart (item) {

  return (dispatch, getState) => {
    if (isArray(item)) {
      let newItems = item.filter(each => each.qty > 0)
      newItems.forEach(_item => {
        dispatch(addItem(_item))
      })
    } else {
      if (item.qty > 0) {
        dispatch(addItem(item))
      }
    }

    function addItem (cache) {

      return {
        type: cacheConst.ADD_CART,
        cache
      }
    }
  }
}

// 更新缓存
export function updateCart (index, item) {

  return (dispatch, getState) => {
    
    dispatch(updateItem(index, item))

    function updateItem (index, cache) {
    
      return {
        type: cacheConst.UPDATE_CART,
        cache,
        index
      }
    }
  }
}

//清空缓存
export function clearCart (index, item) {

  return dispatch => {
    
    dispatch({
      type: cacheConst.CLEAR_CART,
    })
  }
}


// 获取缓存列表
export function getCaches () {

  return (dispatch, getState) => {
    let { caches } = getState()
    if (!caches.key) return []

    dispatch(getCacheList(caches.key))
  }
}

// 删除缓存
export function removeItem (index) {

  return dispatch => dispatch(deleteCacheItem(index))
}

function getCacheList (key) {
  return {
    type: cacheConst.GET_CART_LIST,
    key
  }
}

function deleteCacheItem (index) {
  return {
    type: cacheConst.REMOVE_CACHE_ITEM,
    index
  }
}