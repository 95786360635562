import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  disabled: {
    opacity: .5,
    cursor: 'not-allowed',
  }
}));

function CountingBtn (props) {

  const { className, handleEnd } = props
  const classes = useStyles()
  const [count, setCount] = useState(60);

  function countDown () {

    if (count > 0) {
      return count - 1
    }

    return count
  }

  useEffect(() => {
    const id = setTimeout(() => {
      setCount(countDown());
    }, 1000);
    return () => clearInterval(id);
  });

  useEffect(() => {
    count === 0 && handleEnd()
  }, [count, handleEnd]);

  return (
    <Button
      disableRipple={true}
      className={clsx(className, classes.disabled)}
      variant="contained"
      fullWidth
      size="large"
      type="submit"
      color="primary"
    >Resend after {count}s</Button>
  )
}

export default CountingBtn
