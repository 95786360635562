const statusList = [
  {
    label: 'Order created',
    value: 201
  },
  {
    label: 'Awaiting confirmation',
    value: 202
  },
  {
    label: 'Awaiting payment',
    value: 203
  },
  {
    label: 'Awaiting shipment',
    value: 204
  },
  {
    label: 'Order Shipped',
    value: 205
  },
  {
    label: 'Order Closed',
    value: -999
  },
]

export { statusList }