import React, { useState, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from 'common/history'
import { makeStyles } from '@material-ui/styles';
import { AppBar, Container, Toolbar, Badge, Hidden, IconButton, Typography, useScrollTrigger, Menu, MenuItem, Slide } from '@material-ui/core';
import { MenuIcon, NotificationsIcon, InputIcon, AccountCircle } from '@material-ui/icons';

import { SearchInput } from 'components';
import { Cart, Nav } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  toolbar: {
    boxShadow: 'none',
    padding: '34px 0',
    background: '#fff',
    paddingLeft: 15,
    paddingRight: 15,
    zIndex: theme.zIndex.appBar+1
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logo: {
    verticalAlign: 'top',
    marginRight: 6
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontWeight: 'bolder'
  },
  spacingLeft: {
    marginLeft: theme.spacing(1)
  }
}));

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Topbar = props => {
  const { className, loggedIn } = props;
  const [search, setSearch] = useState('')

  const classes = useStyles();

  const [notifications] = useState([]);
  const handleSearchChange = ev => {
    setSearch(ev.target.value)
  }

  const handleSearch = ev => {
    if (ev.key === 'Enter' && search.length) {
      browserHistory.push(`/products?search=${search}`)
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuList = loggedIn
    ? [
        {
          link: '/profile',
          text: 'Profile'
        },
        {
          link: '/orders',
          text: 'Orders'
        },
        {
          link: '/messages',
          text: 'Messages'
        },
        {
          link: '/sign-out',
          text: 'Sign out'
        }
      ]
    : [
        {
          link: '/sign-in',
          text: 'Sign in'
        },
        {
          link: '/sign-up',
          text: 'Sign up'
        },  
      ]

  return (
    <HideOnScroll {...props}>
      <AppBar color="transparent" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Container className={classes.flex}>
            <RouterLink to="/">
              <div className={classes.logoWrapper}>
                <img
                  alt="Logo"
                  className={classes.logo}
                  src="http://images2.diascubes.com/1602681872498-diascubes.jpg"
                />
              </div>
            </RouterLink>
            <div className={classes.flexGrow}></div>
            <Nav/>
            <SearchInput value={search} onChange={handleSearchChange} onKeyUp={handleSearch}/>
            <Hidden mdDown>
              {
                loggedIn ? <Cart className={classes.spacingLeft}/> : null
              }
              <IconButton
                edge="end"
                color="secondary"
                className={classes.spacingLeft}
                onClick={handleMenu}
              >
                <AccountCircle />
              </IconButton>
            </Hidden>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {
                menuList.map(menu => <MenuItem key={menu.link} onClick={handleClose} component={RouterLink} to={menu.link}>{menu.text}</MenuItem>)
              }
            </Menu>
          </Container>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
})

export default connect(mapStateToProps)(Topbar);
