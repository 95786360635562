import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    boxShadow: 'none',
    position: 'relative',
  },
  media: {
    paddingBottom: '100%',
    margin: '0 auto',
    backgroundSize: 'contain'
  },
  mark: {
    width: '100%',
    padding: theme.spacing(1),
    position: 'absolute',
    top: '30%',
    left: 0,
    background: 'rgba(0, 0, 0, .5)',
    color: '#fff',
    textAlign: 'center'
  }
}));

function ProductItem (props) {

  const classes = useStyles();
  const { product, cols, currencyMark, className, loggedIn } = props

  return (
    <Grid item xs={12/cols || false} className={className}>
      <Card className={classes.root}>
        <RouterLink to={`/products/${product.id}`}>
          <CardActionArea>
            {
              product.outOfProduction ? <Typography className={classes.mark} variant="h5">OUT OF PRODUCTION</Typography> : null
            }
            {
              product.coverImageUrl ? (
                <CardMedia
                  className={classes.media}
                  image={product.coverImageUrl + "-homesub"}
                  title={product.productName}
                />
              ) : (
                <Skeleton variant="rect" width="100%" className={classes.media} animation="wave"/>
              )
            }
            <CardContent>
              {
                product.coverImageUrl ? (
                  <>
                    <Typography gutterBottom variant="subtitle2" component="h4">
                      {product.productName}
                    </Typography>
                    {
                      loggedIn
                      ? <Typography variant="h5" color="error" component="p">{currencyMark}{product.price}</Typography>
                      : <Typography variant="h5" color="error" component="p">Inquire price</Typography>
                    }
                  </>
                ) : (
                  <>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  </>
                )
              }
            </CardContent>
          </CardActionArea>
        </RouterLink>
      </Card>
    </Grid>
  );
}

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
})

export default connect(mapStateToProps)(ProductItem);