export const cacheConst = {

  PRODUCT_CACHE_KEY: 'PRODUCT_CACHE_KEY',
  GET_CACHE_KEY: 'GET_CACHE_KEY',
  GET_CART_LIST: 'GET_CART_LIST',
  SAVE_TO_CART: 'SAVE_TO_CART',
  DESTROY_CART: 'DESTROY_CART',
  UPDATE_CART: 'UPDATE_CART',
  ADD_CART: 'ADD_CART',
  REMOVE_CACHE_ITEM: 'REMOVE_CACHE_ITEM',
  CLEAR_CART: 'CLEAR_CART',
}